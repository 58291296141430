import {
  verifyTokenStarted, verifyUserSuccess, verifyTokenEnd,
  userLoginStarted, userLoginFailure, userLogout
} from "../actions/authActions";
import { verifyTokenService, userLoginService } from '../api/auth';
import { getUser } from '../utils/auth';

// handle verify token
export const verifyTokenAsync = (silentAuth = false) => async dispatch => {
  dispatch(verifyTokenStarted(silentAuth));

  const result = await verifyTokenService();
  if (!result.success) {
    dispatch(verifyTokenEnd());
    if ([401, 403].includes(result.statusCode))
      dispatch(userLogout());
    return;
  }

  if (result.statusCode === 204)
    dispatch(verifyTokenEnd());
  else
    dispatch(verifyUserSuccess(Object.assign(result.data, {user: getUser()})));
};

// handle user login
export const userLoginAsync = (username, password) => async dispatch => {
  dispatch(userLoginStarted());

  const result = await userLoginService(username, password);

  if (result.error) {
    dispatch(userLoginFailure(result.error));
    return;
  }

  dispatch(verifyUserSuccess(result.data));
};

// handle user logout
export const userLogoutAsync = () => dispatch => {
  dispatch(userLogout());
};
