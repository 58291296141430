import axios from 'axios';

class CategoriesAPI {
  constructor() {
    this.baseUrl = 'categories';
  }

  getAll() {
    return axios.get(this.baseUrl);
  }

  update(id, data) {
    const formData = new FormData();
    for(const key in data) {
      const value = data[key];
      formData.append(key, value);
    }
    return axios.patch(`${this.baseUrl}/${id}`, formData, {
      headers: {'Content-Type': 'multipart/form-data' }
    });
  }

  async deleteImage(categoryId, imageId) {
    return await axios.delete(`${this.baseUrl}/${categoryId}/simulation/${imageId}`);
  }

  updateMany(categories) {
    return axios.post(`/${this.baseUrl}/bulk`, categories);
  }

  create(data) {
    return axios.post(`/${this.baseUrl}`, data);
  }

  delete(id) {
    return axios.delete(`/${this.baseUrl}/${id}`);
  }

}

export default new CategoriesAPI();
