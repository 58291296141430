import React, { useState } from 'react';
import { userForgotPasswordService } from '../../api/auth';
import '../../assets/styles/pages/login.scss';
import Logo from '../../assets/img/logo/logo_couch.png';
import Translate from '../Translate';
import { NavLink } from 'react-router-dom';

function Forgot() {

  const [busy, setBusy] = useState(false);
  const [error, setError] = useState(null);
  const [completedSuccessfully, setCompletedSuccessfully] = useState(false);

  const username = useFormInput('');

  // handle button click of login form
  const handleForgot = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    setBusy(true);
    userForgotPasswordService(username.value).then(({success, data, error}) => {
      setBusy(false);
      if (!success)
        setError(error);
      else
        setCompletedSuccessfully(true);
    });
  };

  return (
    <div className="login-form">
      <form>
        <h3>שחזור סיסמא</h3>
        <img src={Logo} alt="NewRoom" />
        {completedSuccessfully ? <div className="links">אימייל נשלח בהצלחה<br /><br /><NavLink to={"/login?email=" + username.value}>כניסה למערכת</NavLink></div> : <>
        <div>
          <div><em>שם משתמש</em></div>
          <input type="text" {...username} autoComplete="username" />
        </div>
        <div className="links"><NavLink to="/login">כניסה למערכת</NavLink></div>
        <button type="submit" className="btn btn-primary" disabled={busy} onClick={handleForgot}>{busy ? '...שולח אימייל' : 'שלח'}</button>
        {error && <div className="login-error"><Translate Message={"errors."+ (Array.isArray(error) ? error[0].msg : error)} /></div>}
        </>}
      </form>

    </div>
  );
}

// custom hook to manage the form input
const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange
  }
};


export default Forgot;
