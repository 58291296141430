import React, { useEffect, useState } from 'react';
import PageSpinner from '../PageSpinner';
import { useDispatch } from 'react-redux';
import SimulationsAPI from '../../api/simulations';
import ImagesSliderModal from '../ImagesSliderModal';
import Select from 'react-select';
import './PublicSimulations.scss';
import CategoriesAPI from '../../api/categories';


const Enums = {
  spaces: {
    1: 'חדר שינה',
    2: 'סלון',
    4: 'חדר ילדים',
    8: 'חדר תינוקות',
    16: 'אמבטיה',
    32: 'מטבח',
    64: 'מרפסת',
    128: 'משרד',
    256: 'חדר נער/ה',
    512: 'אחר'
  }
};

function PublicSimulations() {

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [error, setError] = useState(null);
  const [simulations, setSimulations] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedSpace, setSelectedSpace] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);

  // verify token on app load
  useEffect(() => {
    if (isLoading) {
      let itemsToLoad = 2;
      SimulationsAPI.getAll().then(({data, success, error}) => {
        itemsToLoad--;
        if (!itemsToLoad)
          setIsLoading(false);
        if (success)
          setSimulations(data);
        else
          setError(error);
      });
      CategoriesAPI.getAll().then(({data, success, error}) => {
        itemsToLoad--;
        if (!itemsToLoad)
          setIsLoading(false);
        if (success)
          setCategories(data);
        else
          setError(error);
      });
    }
  }, [dispatch, isLoading, simulations, categories]);

  if (isLoading)
    return <PageSpinner caption="...טוען" />;

  if (error)
    return <div>קרתה שגיאה: {error}</div>;

  const getImage = (simulation) => {
    return simulation.imageUrl + '?t=' + simulation.createdAt;
  };

  const getSpaces = () => {
    return Object.keys(Enums.spaces).map(space => {
      return { value: space, label: Enums.spaces[space] };
    }) ;
  };

  const getCategories = () => {
    if (!selectedSpace || !selectedSpace.value)
      return [];
    return categories.filter(c => c.parentCategoryId === Number(selectedSpace.value)).map(c => { return { value: c.id, label: c.name };});
  };

  const getRelevantSimulations = () => {
    if (!selectedSpace || !selectedSpace.value)
      return simulations;
    if (!selectedCategories || !selectedCategories.length)
      return simulations.filter(s => s.Categories.find(c => c.parentCategoryId === Number(selectedSpace.value)));

    const selectedCategoryIds = selectedCategories.map(c => Number(c.value));
    return simulations.filter(s => s.Categories.find(c => selectedCategoryIds.includes(c.id)));
  };

  return (<>
    {selectedImage ? <ImagesSliderModal imageSrc={selectedImage} show={!!selectedImage} onClose={() => setSelectedImage(null)} /> : null}
    <div className="public-simulations">
      <Select
        className="spaces-select"
        isClearable
        value={selectedSpace}
        onChange={(selectedOption) => { setSelectedSpace(selectedOption); setSelectedCategories([]); }}
        options={getSpaces()}
      />
      <Select
        className="categories-select"
        isMulti
        closeMenuOnSelect={false}
        isDisabled={!selectedSpace || !selectedSpace.value}
        value={selectedCategories}
        onChange={(selectedOptions) => setSelectedCategories(selectedOptions)}
        options={getCategories()}
      />
      <div className="public-simulations-container">
        {getRelevantSimulations().map((simulation, index) => {
          return <div key={"simulation" + index}>
            <div title={simulation.name} style={{backgroundImage: `url(${getImage(simulation)})`}} onClick={() => setSelectedImage(getImage(simulation))} />
            <ul>
              {simulation.Categories.map((category, catIndex) => {
                return <li key={'category_' + simulation.id + '_' + catIndex}>
                  {Enums.spaces[category.parentCategoryId]} > {category.name}
                  </li>;
              })}
            </ul>
          </div>;
        })}
    </div>
      <footer>&copy; 2020 NewRoom</footer>
    </div>
  </>);
}


export default PublicSimulations;
