import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Home from './components/pages/Home';
import Login from './components/pages/Login';
import Forgot from './components/pages/Forgot';
import Reset from './components/pages/Reset';
import Verify from './components/pages/Verify';
import Dashboard from './components/pages/Dashboard';
import Suppliers from './components/pages/Suppliers';
import PublicDesignerPage from './components/pages/PublicDesignerPage';
import PublicSimulationsPage from './components/pages/PublicSimulations';
import DesignerDrillDown from './components/pages/UserDrillDownPage';
import Designers from './components/pages/Users';
import Categories from './components/pages/Categories';
import Simulations from './components/pages/Simulations';
import AddSimulations from './components/pages/AddSimulations';
import CategoryDrillDown from './components/pages/Categories';
import NotFound from './components/pages/404';

import { verifyTokenAsync } from './asyncActions/authAsyncActions';

import PrivateRoute from './components/routes/PrivateRoute';
import PublicRoute from './components/routes/PublicRoute';
import EnvironmentTag from './components/EnvironmentTag';
import Toolbar from './components/layout/Toolbar';
import PageSpinner from './components/PageSpinner';

function App() {
  const authObj = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const { authLoading, isAuthenticated, user } = authObj;
  const [requiresAuth, setRequiresAuth] = useState(true);

  // verify token on app load
  useEffect(() => {
    if (window.location.pathname.startsWith('/admin'))
      dispatch(verifyTokenAsync());
    else
        setRequiresAuth(false);
  }, [dispatch]);

  // checking authentication
  if (authLoading && requiresAuth) {
    return <PageSpinner caption="...טוען" />
  }

  const getToolbarItems = () => {
    return [
      {to: '/admin', name: 'ראשי', exact: true},
      {to: '/admin/designers', name: 'מעצבים', exact: true},
      {to: '/admin/categories', name: 'קטגוריות', exact: true},
      {to: '/admin/simulations', name: 'הדמיות', exact: true},
      {to: '/admin/suppliers', name: 'ספקים', exact: true},
    ];
  };

  return (
    <div className="App">
      <BrowserRouter>
        <div className="content">
          <Toolbar items={getToolbarItems()} />
          <Switch>
            <PublicRoute exact path="/" component={Home} />
            <PublicRoute path="/login" component={Login} />
            <PublicRoute path="/forgot" component={Forgot} />
            <PublicRoute path="/reset" component={Reset} />
            <PublicRoute path="/verify/:id" component={Verify} />
            <Route exact path="/designer/:id" component={PublicDesignerPage} />
            <PrivateRoute exact path="/admin/dashboard" roles={['admin']} component={Dashboard} />
            <PrivateRoute exact path="/admin/suppliers" roles={['admin']} component={Suppliers} />
            <PrivateRoute exact path="/admin/designers" roles={['admin']} component={Designers} />
            <PrivateRoute exact path="/admin/designer/:id" component={DesignerDrillDown} />
            <PrivateRoute exact path="/admin/simulations" roles={['admin']} component={Simulations} />
            <PrivateRoute exact path="/admin/add-simulations" roles={['admin']} component={AddSimulations} />
            <PrivateRoute exact path="/admin/categories" roles={['admin']} component={Categories} />
            <PrivateRoute exact path="/admin/category/:id" roles={['admin']} component={CategoryDrillDown} />
            <PrivateRoute exact path="/admin" component={() => <Redirect to={user.role === 'admin' ? '/admin/dashboard' :`/admin/designer/${user.id}`} />} />
            <Route component={NotFound} />
            <Redirect to={isAuthenticated ? '/admin' : '/login'} />
          </Switch>
        </div>
      </BrowserRouter>
      <EnvironmentTag />
      <div className="modals" />
    </div>
  );
}

export default App;
