import axios from 'axios';

class AnalyticsAPI {
  constructor() {
    this.baseUrl = 'analytics';
  }

  get() {
    return axios.get(this.baseUrl);
  }
}

export default new AnalyticsAPI();
