import React, { useRef, useEffect, useState } from 'react';
import {polyfill} from "mobile-drag-drop";
const cancelEvent = (evt) => evt.preventDefault();
polyfill({holdToDrag: 300});
try {
  window.addEventListener('touchmove', function () {
  }, {passive: false});
} catch(e) {}

const Draggable = ({children, tag: Tag = "div", draggable, index: dropIndex, onDrop: onDropHandler, onDragStart: onStart, onDragEnd: onEnd, onDragClassName, ...restProps }) => {

  const componentRef = useRef();
  const [hasHandle, setHasHandle] = useState(false);

  const regExp = onDragClassName ? new RegExp('(?:^|\\s)'+onDragClassName+'(?!\\S)') : null;

  const onDragStart = (evt) => {
    if (onDragClassName) {
      if (evt.target.className)
        evt.target.className = `${evt.target.className} ${onDragClassName}`;
      else
        evt.target.className = onDragClassName;
    }
    if (!evt.dataTransfer.getData("dragIndex"))
      evt.dataTransfer.setData("dragIndex", dropIndex);
    if (onStart)
      onStart(evt);
  };

  const onDrop = (evt) => {
     // We can think about moving this into a component that receives a component
    evt.preventDefault();
    evt.stopPropagation();
    let dragIndex = evt.dataTransfer.getData("dragIndex");
    // Try to convert to number
    if (typeof dropIndex === 'number' && !isNaN(+dragIndex)) {
      dragIndex = +dragIndex;
    }
    if (dragIndex !== dropIndex)
      onDropHandler(dragIndex, dropIndex);
  };

  const onDragEnd = (evt) => {
    if (onDragClassName)
      evt.target.className = evt.target.className.replace(regExp, '');
    if (hasHandle) {
      const { current } = componentRef;
      current.removeAttribute('draggable');
    }
    if (onEnd)
      onEnd(evt);
  };

  useEffect(() => {
    const { current } = componentRef;
    const handle = current.getElementsByClassName('drag-handle')[0];
    if (draggable) {
      if (!handle)
        current.setAttribute('draggable', true);
      else {
        setHasHandle(true);
        handle.onmousedown = (e) => {
          current.setAttribute('draggable', true);
        };
        handle.onmouseup = (e) => {
          current.removeAttribute('draggable');
        };
      }
    }
  }, [draggable]);

  return (
    <Tag ref={componentRef} onDragEnter={cancelEvent} onDragStart={onDragStart} onDragEnd={onDragEnd} onDragOver={cancelEvent} onDrop={onDrop} {...restProps}>
      {children}
    </Tag>);
};

export default Draggable;
