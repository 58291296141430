import React, { useEffect, useState } from 'react';
import AnalyticsAPI from '../../api/analytics';
import PageSpinner from '../PageSpinner';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import './Dashboard.scss';

function Dashboard() {

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [analytics, setAnalytics] = useState([]);

  // verify token on app load
  useEffect(() => {
    if (isLoading)
      AnalyticsAPI.get().then(({data, success, error}) => {
        setIsLoading(false);
        if (success)
          setAnalytics(data);
        else
          setError(error);
      });
  }, [dispatch, isLoading, analytics]);

  if (isLoading)
    return <PageSpinner caption="...טוען" />;

  if (error)
    return <div>קרתה שגיאה: {error}</div>;

  return (
    <div className="dashboard-page">
      <div className="dashboard-container">
        <div className="box">
          <h3><NavLink to="/admin/designers" exact>מעצבים</NavLink></h3>
          <h4>{analytics.designers}</h4>
        </div>
        <div className="box">
          <h3><NavLink to="/admin/simulations" exact>הדמיות</NavLink></h3>
          <h4>{analytics.simulations}</h4>
        </div>
        <div className="box">
          <h3><NavLink to="/admin/suppliers" exact>ספקים</NavLink></h3>
          <h4>{analytics.suppliers || 0}</h4>
        </div>
      </div>
    </div>
  );
}


export default Dashboard;
