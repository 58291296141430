import React, { useState } from 'react';
import { Modal as ReactModal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';

function Modal(props) {
  const [isOpen, setIsOpen] = useState(true);

  function toggle() {
    setIsOpen(!isOpen);
  }

  function handle(key) {
    const handler = props.handlers[key].handler;
    if (!handler || handler())
        toggle();
  }

  function getButtons() {
    if (props.handlers)
      return Object.keys(props.handlers).map((key, index) => <Button key={"button_" + index} color={props.handlers[key].primary ? "primary" : "secondary"} onClick={() => handle(key)}>
                {key}
              </Button>);

    return  <><Button color="primary" onClick={toggle}>
                אישור
              </Button>{' '}
              <Button color="secondary" onClick={toggle}>
                ביטול
              </Button></>;
  }

  return (<ReactModal
            isOpen={isOpen}
            toggle={toggle}
            className={props.className}>
            <ModalHeader toggle={toggle}>{props.header || "Info"}</ModalHeader>
            <ModalBody>
              {props.message || "Modal body"}
            </ModalBody>
            <ModalFooter>
              {getButtons()}
            </ModalFooter>
          </ReactModal>);
}

export default Modal;

