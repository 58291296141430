import React from 'react';
import DesignersAPI from '../../api/designers';
import PageSpinner from '../../components/PageSpinner';
import ModalManager from "../../utils/modalManager";
import ImagesSliderModal from '../../components/ImagesSliderModal';
import Converters from '../../utils/converters';
import { WhatsappShareButton, WhatsappIcon } from 'react-share';
import { MdPhotoCamera, MdAddCircle, MdClear, MdEdit, MdPhone } from 'react-icons/md';
import { FaPlusCircle, FaHeart, FaRegHeart } from 'react-icons/fa';
import '../../assets/styles/pages/userdrilldown.scss';
import Dropzone from 'react-dropzone';
import SaveChangesBar from '../../components/SaveChangesBar';
import General from '../../utils/general';
import ToggleButton from 'react-toggle-button';
import $ from 'jquery';
import Draggable from '../helpers/Draggable';
import FilesHelper from '../../utils/files-helper';
const SampleProfilePhoto = 'https://static.newroom.co.il/assets/profile.jpg';
const SampleCoverPhoto = 'https://static.newroom.co.il/assets/cover.jpg';
const borderRadiusStyle = { borderRadius: 2 };

const spacesImages = {
  1: 'https://static.newroom.co.il/assets/bedroom.svg',
  2: 'https://static.newroom.co.il/assets/livingroom.svg',
  4: 'https://static.newroom.co.il/assets/chidrenroom.svg',
  8: 'https://static.newroom.co.il/assets/babyroom.svg',
  16: 'https://static.newroom.co.il/assets/bathroom.svg',
  32: 'https://static.newroom.co.il/assets/kitchen.svg',
  64: 'https://static.newroom.co.il/assets/balcony.svg',
  128: 'https://static.newroom.co.il/assets/office.svg',
  256: 'https://static.newroom.co.il/assets/teenager.svg',
  512: 'https://static.newroom.co.il/assets/other.svg',
};

const Enums = {
  locations: {
    1: 'מרכז',
    2: 'דרום',
    4: 'צפון',
    8: 'ירושלים',
    16: 'כל הארץ',
    32: 'חו״ל',
    64: 'כל הארץ והעולם'
  },
  styles: {
    1: 'כפרי',
    2: 'מודרני',
    4: 'רטרו',
    8: 'תעשייתי',
    16: 'נורדי',
    32: 'בוהו שיק',
    64: 'אקלקטי',
    128: 'פרובאנס',
    256: 'כל הסגנונות'
  },
  spaces: {
    1: 'חדר שינה',
    2: 'סלון',
    4: 'חדר ילדים',
    8: 'חדר תינוקות',
    16: 'אמבטיה',
    32: 'מטבח',
    64: 'מרפסת',
    128: 'משרד',
    256: 'חדר נער/ה',
    512: 'אחר',
    1024: 'הכל'
  },
  budget: {
    1: 'עד 10,000 ש״ח',
    2: 'מ-10,000-30,000 ש״ח',
    4: 'מעל 30,000 ש״ח',
    8: 'כל התקציבים'
  }
};


class UserDrillDownPage extends React.Component {

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onDrop = this.onDrop.bind(this);

		this.userId = this.props.match.params.id;

		if (!this.userId)
		  this.props.history.push('/');

		this.changes = {};

    this.state = {
      user: null,
      profile: null,
      cover: null,
      hasChanges: false,
      loading: true,
      liked: false,
      images: [],
      saving: null,
      message: null,
      selectedImage: null,
      editing: null,
      isDragging: false,
      editedValues: {}
    };
  }

  componentDidMount() {
    this.setState({loading: true});
    this.fetchUser();
  }

  fetchUser() {
    DesignersAPI.getEditDetailsById(this.userId).then(result => {
      if (!result.success)
        this.props.history.push('/');
      else {
        this.userData = result.data;
        this.changes = {};
        this.reset();
      }
    });
  }

  reset() {
    this.changes = {};
    this.setState({user: General.clone(this.userData), hasChanges: false, profile: null, message: null, cover: null, images: [], selectedImage: null, loading: false, saving: null})
  }

  onChange({target}) {
    const name = target.name,
      value = target.value;

    if (name.startsWith('user.')) {
      const user = General.clone(this.state.user);
      user[name.replace('user.', '')] = value;
      this.changes[name.replace('.', '')] = value;
      this.setState({user, hasChanges: true});
    }
  }

  handleSubmit(e){
    e.preventDefault();
  }

  deleteImage(imageId) {
    DesignersAPI.deleteImage(this.userId, imageId).then(() => this.fetchUser());
    return true;
  }

  deleteDraftImage(key, imageId) {
    delete this.changes[`${key}${imageId}`];
    if (this.changes.captions)
      delete this.changes.captions[`${key}${imageId}`];
    const filesToUpload = this.state[key];
    if (filesToUpload) {
      filesToUpload.splice(imageId, 1);
      this.setState({[key]: filesToUpload});
    }
    return true;
  }

  promptDelete(imageId, key, draft = false) {
    const modalButtons = {
                        "מחק" : { handler: draft ? this.deleteDraftImage.bind(this, key, imageId) : this.deleteImage.bind(this, imageId, draft)},
                        "ביטול" : {primary: true}
                       };
    const content = <div>
      האם אתה בטוח שברצונך למחוק את התמונה?<br />הפעולה הינה בלתי הפיכה
    </div>;
    let config = {
        header: "מחק",
        message: content,
        footer: "footer",
        handlers: modalButtons
    };
    ModalManager.createModal(config);
  }

  promptEditCaption(imageId, key, draft = false) {

    let caption = draft ? this.state[key][imageId].caption : this.state.user.Images.find(i => i.id === imageId).caption;
    const onChange = ({target}) => {
      caption = target.value;
    };

    const updateCaption = () => {
      if (!this.changes.captions)
          this.changes.captions = {};
      if (draft) {
        const images = General.clone(this.state[key]);
        this.changes.captions[`${key}${imageId}`] = caption;
        images[imageId].caption = caption;
        this.setState( {[key]: images});
      } else {
        const user = General.clone(this.state.user);
        this.changes.captions[imageId] = caption;
        const image = user.Images.find(i => i.id === imageId);
        image.caption = caption;
        this.setState( {user, hasChanges: true});
      }
      return true;
    };

    const modalButtons = {
                        "עדכן" : { handler: updateCaption},
                        "ביטול" : {primary: true}
                       };
    const content = <div className="captions-editor">
      <em>תיאור לתמונה</em>
      <input type="text" name="caption" defaultValue={caption} onChange={onChange} />
    </div>;
    let config = {
        header: "תיאור לתמונה",
        message: content,
        footer: "footer",
        handlers: modalButtons
    };
    ModalManager.createModal(config);
  }

  saveChanges(){
    if (Object.keys(this.changes).length) {
      this.setState({message: 'שומר', saving: "שומר שינויים"});
      if (this.changes.captions)
        this.changes.captions = JSON.stringify(this.changes.captions);
      DesignersAPI.update(this.userId, this.changes).then(() => this.fetchUser());
    }
    else {
      this.reset();
    }
  }

  getMaxDimensionsBySection(section) {
    if (section === 'profile')
      return {MAX_WIDTH: 300, MAX_HEIGHT: 300};
    if (section === 'cover')
      return {MAX_WIDTH: 1920, MAX_HEIGHT: 1400};
    return {MAX_WIDTH: 1280, MAX_HEIGHT: 1024};
  }

  onFileSelected(section, file) {
    const caption = '';
    if (file.type.match(/image.*/)) {
      this.setState({saving: "מקווץ תמונות"});
      const {MAX_WIDTH, MAX_HEIGHT} = this.getMaxDimensionsBySection(section);
      FilesHelper.ResizeImage(file, MAX_WIDTH, MAX_HEIGHT).then(({src: url, file: resizedFile}) => {
        this.changes[section] = resizedFile;
        this.setState({[section]: {url, caption}, hasChanges: true, saving: null});
      });
    }
  }

  getFileChangesKey(section, index) {
    if (!this.changes[section+index])
      return section + index;
    return this.getFileChangesKey(section, (index+1));
  }

  onFilesSelected(section, files) {
    const promises = [];
    const caption = '';
    const {MAX_WIDTH, MAX_HEIGHT} = this.getMaxDimensionsBySection(section);
    for (let i=0, len=files.length ; i<len ; i++) {
      if (files[i].type.match(/image.*/)) {
        promises.push(FilesHelper.ResizeImage(files[i], MAX_WIDTH, MAX_HEIGHT));
      }
    }
    if (promises.length)
      this.setState({saving: "מקווץ תמונות"});
    Promise.all(promises).then((results) => {
      this.setState({[section]: this.state[section].concat(results.map(r => {return {url: r.src, caption};})), hasChanges: true, saving: null});
      results.forEach(({file}, index) => this.changes[this.getFileChangesKey(section, index)] = file);
    });
  }

  get WasLiked() {
    return this.state.liked;
  }

  get NumberOfLikes() {
    return (this.state.user.AdditionalInfo && this.state.user.AdditionalInfo.numberOfLikes) || 0;
  }

  onLikeClicked() {
    if (!this.WasLiked) {
      const user = General.clone(this.state.user);
      if (!user.AdditionalInfo)
        user.AdditionalInfo = {numberOfLikes: 0};
      user.AdditionalInfo.numberOfLikes++;
      this.setState({user, liked: true});
    }
  }

  getLikeTooltipText() {
    if (this.NumberOfLikes)
      return "אהוב";

    return "היה הראשון לאהוב";
  }

  renderLikeButton() {
    if (this.state.liked)
      return <FaHeart />;
    return <FaRegHeart />;
  }

  setEditable(section) {
    this.setState({editing: section});
    setTimeout(() => {
      $(`#${section}Select`).focus();
    }, 0);
  }

  getListItem(section, key, value) {
    if (section === 'spaces')
      return <li style={{backgroundImage: `url(${spacesImages[key]})`}} key={`${section}_${key}`}>{value}</li>;
    return <li key={`${section}_${key}`}>{value}</li>;
  }

  getMultipleSelectionField(section) {
    const enumValues = Enums[section];
    const enumValueKeys = Object.keys(enumValues);
    const value = this.changes[section] || (this.state.user.AdditionalInfo && this.state.user.AdditionalInfo[section]) || [enumValueKeys[enumValueKeys.length-1]];
    if (this.state.editing === section) {
      return <><div>
          <select id={section + "Select"} multiple name={section} defaultValue={value ? General.getValueArrayFromFlag(Object.keys(enumValues), value) : []}>
            {enumValueKeys.map(key => <option key={section + '_edit_' + key} value={key}>{enumValues[key]}</option>)}
          </select>
      </div>
      <div className="editor-buttons">
        <button className="btn btn-primary" onClick={(evt) => {
          const newValue = $(`#${section}Select`).val();
          this.changes[section] = 0;
          newValue.forEach(key => {
            this.changes[section] = General.addFlag(this.changes[section], +key);
          });
          this.setState({'editing': null, hasChanges: true});
          evt.stopPropagation();
          evt.preventDefault();
        }}>אישור</button>
        <button className="btn" onClick={(evt) => {this.setState({'editing': null}); evt.stopPropagation(); evt.preventDefault();}}>ביטול</button>
      </div>
      </>;
    }

    const selectedValues = value ? enumValueKeys.filter(key => General.hasFlag(value, +key)) : [enumValueKeys[enumValueKeys.length-1]];
    return <ul className={section + '-section'}>
            {selectedValues ? selectedValues.map(key => this.getListItem(section, key, enumValues[key])) : <li>ללא הגבלה</li>}
          </ul>;
  }

  getBoolValue(bool, bool2, defaultValue) {
    if (bool !== undefined && bool !== null)
      return !!bool;
    if (bool2 !== undefined && bool2 !== null)
      return !!bool2;
    return defaultValue;
  }

  getOnline() {
    const online = this.getBoolValue(this.changes.online, this.state.user.AdditionalInfo && this.state.user.AdditionalInfo.online, true);
    return <ToggleButton
      value={online}
      thumbStyle={borderRadiusStyle}
      trackStyle={borderRadiusStyle}
      activeLabel="כן"
      inactiveLabel="לא"
      onToggle={(value) => {
        this.changes.online = !value;
        this.setState({hasChanges: true});
      }} />;
  }

  showModalDialog({target}) {
    this.showmodaltimeout = setTimeout(() => {
      let backgroundImage = (target.currentStyle || window.getComputedStyle(target, false)).backgroundImage;
      backgroundImage = backgroundImage.slice(4, -1).replace(/"/g, "");
      this.setState({selectedImage: { url: backgroundImage, caption: target.title }});
    }, 100);
  }

  getProfilePhoto() {
    if (this.state.profile)
      return this.state.profile.url;

    const profilePhoto = this.state.user.Images.find(image => image.section === 'profile');
    if (profilePhoto)
      return profilePhoto.imageUrl + '?t=' + profilePhoto.updatedAt;

    return SampleProfilePhoto;
  }

  getCoverPhoto() {
    if (this.state.cover)
      return this.state.cover.url;

    const coverPhoto = this.state.user.Images.find(image => image.section === 'cover');
    if (coverPhoto)
      return coverPhoto.imageUrl + '?t=' + coverPhoto.updatedAt;

    return SampleCoverPhoto;
  }

  getDesignerImages() {
    const userImages = this.state.user.Images.filter(i => i.section === 'regular');
    const draggable = !this.state.images.length;
    const imagesList = userImages.map((image, index) => <Draggable tag="li" key={'image_' + index} index={index} draggable={draggable} onDrop={this.onDrop} onDragStart={this.onDragStart} onDragEnd={this.onDragEnd} title={image.caption} style={{backgroundImage: `url(${image.imageUrl}?t=${image.createdAt})`}} onMouseUp={(evt) => this.showModalDialog(evt)}>
      <MdClear onMouseDown={() => this.promptDelete(image.id)} />
      <MdEdit className="edit" onMouseDown={() => this.promptEditCaption(image.id)} />
      {/*<em>{image.id}</em>*/}
    </Draggable>);

    const rawImages = this.state.images.map((image, index) => <li key={"imgBox_"+index} title={image.caption} style={{backgroundImage: `url(${image.url})`}} onMouseUp={(evt) => this.showModalDialog(evt)}>
        <MdClear onMouseDown={() => this.promptDelete(index, 'images', true)} />
        <MdEdit className="edit" onMouseDown={() => this.promptEditCaption(index, 'images', true)} />
    </li>);

    let images = imagesList.concat(rawImages);

    if (!images.length)
      images = [0,1,2,3,4,5,6,7,8,9].map(i => <li key={"example_" + i} title="קרדיט: ניורום" onMouseUp={(evt) => this.showModalDialog(evt)}><em>דוגמא</em></li>);
    return images;
  }

  onDrop(dragIndex, dropIndex) {
    clearTimeout(this.showmodaltimeout);
    const user = General.clone(this.state.user);
    let regularImages = user.Images.filter(i => i.section === 'regular');
    const otherImages = user.Images.filter(i => i.section !== 'regular');
    General.reorder(regularImages, dragIndex, dropIndex);
    user.Images = regularImages.concat(otherImages);
    this.changes.order = regularImages.map(i => i.id);
    this.setState({user, hasChanges: true});
  }

  onDragStart = (evt) => {
    this.setState({ isDragging: true });
  };

  onDragEnd = (evt) => {
    this.setState({ isDragging: false });
  };

  render() {
    if (this.state.loading)
      return <PageSpinner />;

    return (<>
      {this.state.selectedImage ? <ImagesSliderModal className="with-editor" imageSrc={this.state.selectedImage && this.state.selectedImage.url} caption={this.state.selectedImage && this.state.selectedImage.caption} show={!!this.state.selectedImage} onClose={() => this.setState({selectedImage: null})} /> : null}
      <div className={'user-drilldown' + (this.state.saving ? ' busy' : '')}>
        <PageSpinner caption={this.state.saving} />
        <div className="user-header">
          <div className="cover-photo" style={{"backgroundImage": `url(${this.getCoverPhoto()})`}}>
            <Dropzone multiple={false} accept="image/*" onDrop={acceptedFiles => this.onFileSelected('cover', acceptedFiles[0])}>
              {({getRootProps, getInputProps}) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="upload"><MdPhotoCamera size={20} /> שינוי תמונה...</div>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <div className="user-info">
            <div className="profile-photo-container">
              <div className="profile-photo">
                <div className="image-container" style={{"backgroundImage": `url(${this.getProfilePhoto()})`}}>
                </div>
                <Dropzone multiple={false} accept="image/*" onDrop={acceptedFiles => this.onFileSelected('profile', acceptedFiles[0])}>
                  {({getRootProps, getInputProps}) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <FaPlusCircle className="action-upload" />
                      </div>
                    </section>
                  )}
                </Dropzone>

                <WhatsappShareButton className="action-share" url={`${process.env.REACT_APP_API_URL}/public/${this.userId}`}>
                  <WhatsappIcon size={26} round />
                </WhatsappShareButton>
              </div>
            </div>
            <h2><input type="text" name="user.name" value={this.state.user.name} onChange={this.onChange} autoComplete="false" /></h2>
            <h3 className="phone"><MdPhone /><input type="text" name="user.phone" value={this.state.user.phone} onChange={this.onChange} autoComplete="false" /></h3>
            <p className="likes"><span className={"like-action" + (this.WasLiked ? " disabled" : "")} onClick={() => this.onLikeClicked()}>{this.renderLikeButton()}<em>{this.NumberOfLikes > 0 ? Converters.thousandSeparator(this.NumberOfLikes) : ""}</em></span>{!this.WasLiked ? <span className="tooltip">{this.getLikeTooltipText()}</span> : null}</p>
          </div>
        </div>
        <div className="user-content">
          <div className="main">
            <div className="add-new-image">
                <Dropzone multiple={true} accept="image/*" onDrop={acceptedFiles => this.onFilesSelected('images', acceptedFiles)}>
                {({getRootProps, getInputProps}) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      הוסיפו תמונות <MdAddCircle />
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
             <ul className="images">
              {this.getDesignerImages()}
             </ul>
          </div>
           <div className="sidebar">
            <div onClick={() => this.setEditable('locations')}>
              <h4>מיקום גיאוגרפי<MdEdit /></h4>
              {this.getMultipleSelectionField('locations')}
            </div>
            <div className="toggle-button">
              <h4>מעצב/ת אונליין</h4>
              {this.getOnline()}
            </div>
            <div onClick={() => this.setEditable('styles')}>
              <h4>סגנונות<MdEdit /></h4>
              {this.getMultipleSelectionField('styles')}
            </div>
            <div onClick={() => this.setEditable('spaces')}>
              <h4>סוגי חללים<MdEdit /></h4>
              {this.getMultipleSelectionField('spaces')}
            </div>
            <div onClick={() => this.setEditable('budget')}>
              <h4>תקציבי לקוח<MdEdit /></h4>
               {this.getMultipleSelectionField('budget')}
            </div>
          </div>
        </div>
        <footer>&copy; 2020 NewRoom</footer>
        <SaveChangesBar HasChanges={this.state.hasChanges && !this.state.isDragging} Message={this.state.message} OnSave={() => this.saveChanges()} OnCancel={() => this.reset()} />
      </div>
    </>);
  }



}
export default UserDrillDownPage;
