import axios from "axios";

// verify refresh token to generate new access token if refresh token is present
export const verifyTokenService = async () => {
  return axios.get(`/token`);
};

// verify refresh token to generate new access token if refresh token is present
export const userReset = async (email, password) => {
  return axios.post(`/reset`, { email, password });
};

// user login API to validate the credential
export const userLoginService = async (email, password) => {
  return axios.post(`/login`, { email, password });
};

// user login API to validate the credential
export const userForgotPasswordService = async (email) => {
  return axios.post(`/forgot`, { email });
};

export const validateSignature = async (sig) => {
  return axios.get(`/validate?sig=${sig}`);
};

// verify email address
export const verifyEmail = async (verification) => {
  return axios.post(`/verify`, {verification});
};

// manage user logout
export const userLogoutService = async () => {
  return axios.post(`/logout`);
};

// get reset link
export const getResetLink = async (email) => {
  return axios.post(`/resetlink`, { email });
};
