import React, { useEffect, useState, useCallback } from 'react';
import PageSpinner from '../PageSpinner';
import { useDispatch } from 'react-redux';
import SimulationsAPI from '../../api/simulations';
import ImagesSliderModal from '../ImagesSliderModal';
import Select from 'react-select';
import './Simulations.scss';
import CategoriesAPI from '../../api/categories';
import { NavLink } from 'react-router-dom';
import { MdClear } from 'react-icons/md';
import ModalManager from '../../utils/modalManager';
import SaveChangesBar from '../SaveChangesBar';


const Enums = {
  spaces: {
    1: 'חדר שינה',
    2: 'סלון',
    4: 'חדר ילדים',
    8: 'חדר תינוקות',
    16: 'אמבטיה',
    32: 'מטבח',
    64: 'מרפסת',
    128: 'משרד',
    256: 'חדר נער/ה',
    512: 'אחר'
  }
};

function Simulations() {

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [error, setError] = useState(null);
  const [simulations, setSimulations] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedSpace, setSelectedSpace] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [imagesToDelete, setImagesToDelete] = useState([]);

  const setSimulationCode = (simulation, code) => {
    simulation.code = code;
    setSimulations([...simulations]);
  };

  const setErrorMessage = (error) => {
    if (Array.isArray(error))
      setError(error[0].msg || error[0]);
    else
      setError(error);
  }

  const saveSimulation = (simulation) => {
    SimulationsAPI.update(simulation.id, {code: simulation.code}).then(({success, data, error}) => {
      if (error) {
        setErrorMessage(error);
      }
    });
  };

  const getSimulations = useCallback(() => {
    setIsLoading(true);
    SimulationsAPI.getAll().then(({data, success, error}) => {
        if (success)
          setSimulations(data);
        else
          setErrorMessage(error);
        setIsLoading(false);
      });
  }, []);

  // verify token on app load
  useEffect(() => {
    if (isLoading) {
      getSimulations();
      CategoriesAPI.getAll().then(({data, success, error}) => {
        setIsLoading(false);
        if (success)
          setCategories(data);
        else
          setErrorMessage(error);
      });
    }
  }, [dispatch, isLoading, simulations, categories, getSimulations]);

  if (isLoading)
    return <PageSpinner caption="...טוען" />;

  const getImage = (simulation) => {
    return simulation.imageUrl + '?t=' + simulation.createdAt;
  };

  const getSpaces = () => {
    return Object.keys(Enums.spaces).map(space => {
      return { value: space, label: Enums.spaces[space] };
    }) ;
  };

  const getCategories = () => {
    if (!selectedSpace || !selectedSpace.value)
      return [];
    return categories.filter(c => c.parentCategoryId === Number(selectedSpace.value)).map(c => { return { value: c.id, label: c.name };});
  };

  const getRelevantSimulations = () => {
    if (!selectedSpace || !selectedSpace.value)
      return simulations;
    if (!selectedCategories || !selectedCategories.length)
      return simulations.filter(s => s.Categories.find(c => c.parentCategoryId === Number(selectedSpace.value)));

    const selectedCategoryIds = selectedCategories.map(c => Number(c.value));
    return simulations.filter(s => s.Categories.find(c => selectedCategoryIds.includes(c.id)));
  };

  const getFilteredRelevantSimulations = () => {
    const simulations = getRelevantSimulations();
    return simulations.filter(s => !imagesToDelete.includes(s.id));
  };

  const deleteImages = () => {
    SimulationsAPI.deleteBulk(imagesToDelete).then(() => {
      setImagesToDelete([]);
      getSimulations();
    });
  };

  const promptDelete = (imageId) => {
    const modalButtons = {
                        "מחק" : { handler: markAsDelete.bind(this, imageId)},
                        "ביטול" : {primary: true}
                       };
    const content = <div>
      האם אתה בטוח שברצונך למחוק את התמונה?<br />הפעולה הינה בלתי הפיכה
    </div>;
    let config = {
        header: "מחק",
        message: content,
        footer: "footer",
        handlers: modalButtons
    };
    ModalManager.createModal(config);
  };

  const markAsDelete = (id) => {
    setImagesToDelete([...imagesToDelete, id]);
    return true;
  };

  return (<>
    {selectedImage ? <ImagesSliderModal imageSrc={selectedImage} show={!!selectedImage} onClose={() => setSelectedImage(null)} /> : null}
    <div className="simulations-page">
      {error ? <div>קרתה שגיאה: {Array.isArray(error) ? error[0].msg || error[0] : error}</div> : null}
      <div className="add-simulations"><NavLink to="/admin/add-simulations">הוספת הדמיות</NavLink></div>
      <Select
        className="spaces-select"
        isClearable
        value={selectedSpace}
        onChange={(selectedOption) => { setSelectedSpace(selectedOption); setSelectedCategories([]); }}
        options={getSpaces()}
      />
      <Select
        className="categories-select"
        isMulti
        closeMenuOnSelect={false}
        isDisabled={!selectedSpace || !selectedSpace.value}
        value={selectedCategories}
        onChange={(selectedOptions) => setSelectedCategories(selectedOptions)}
        options={getCategories()}
      />
      <div className="public-simulations-container">
        {getFilteredRelevantSimulations().map((simulation, index) => {
          return <div key={"simulation" + index}>
            <div title={simulation.name} style={{backgroundImage: `url(${getImage(simulation)})`}} onMouseUp={() => setSelectedImage(getImage(simulation))} />
            <MdClear onMouseDown={(evt) => {promptDelete(simulation.id); evt.stopPropagation(); evt.preventDefault();}} />
            <div className="simulation-footer">
              <ul>
                {simulation.Categories.map((category, catIndex) => {
                  return <li key={'category_' + simulation.id + '_' + catIndex}>
                    {Enums.spaces[category.parentCategoryId]} > {category.name}
                    </li>;
                })}
              </ul>
              <div className="code">
                <label>קוד: <input type="number" onChange={(evt) => setSimulationCode(simulation, evt.target.value)} placeholder="הכנס קוד מוצר" name="code" value={simulation.code || ''} /></label><button onClick={() => saveSimulation(simulation)}>שמור</button>
              </div>
            </div>
          </div>;
        })}
    </div>
      <SaveChangesBar HasChanges={imagesToDelete.length > 0} DisableSave={!!error} Message={error} OnCancel={() => setImagesToDelete([])} OnSave={deleteImages} />
      <footer>&copy; 2020 NewRoom</footer>
    </div>
  </>);
}


export default Simulations;
