import PropTypes from 'prop-types';
import React from 'react';
import { Spinner } from 'reactstrap';
import '../assets/styles/components/_page-spinner.scss';

const PageSpinner = ({ color = 'primary', caption = null }) => {
  return (
    <div className="page-spinner">
      <Spinner color={color} />
      <div>{caption}</div>
    </div>
  );
};

PageSpinner.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
  ]),
};

export default PageSpinner;
