import i18next from 'i18next';
const locales = require.context(
  '../locales',
  true,
  /[A-Za-z0-9-_,\s]+\.json$/i
);

const extractLocale = (filename) => {
  const matched = filename.match(/([A-Za-z0-9-_]+)\./i);
  if (matched && matched.length > 1)
    return matched[1];
  return null;
};

const resources = {};

locales.keys().forEach((key) => {
  const locale = extractLocale(key);
  if (locale)
    resources[locale] = { translation: locales(key) };
});

i18next.init({
  lng: process.env.REACT_APP_DEFAULT_LANGUAGE || navigator.language.slice(0, 2) || 'en',
  debug: true,
  resources
});
