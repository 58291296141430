import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

// handle the private routes
function PrivateRoute({ component: Component, roles, ...rest }) {
  const { isAuthenticated, user } = useSelector(state => state.auth);
  return (
    <Route {...rest} render={(props) => {
      if (!isAuthenticated)
        return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;

      if (roles && !roles.includes(user.role))
        return <Redirect to={{ pathname: '/admin', state: { from: props.location } }} />;

      return <Component {...props} />;
    }}/>
  )
}

export default PrivateRoute;
