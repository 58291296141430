import React, { useEffect, useState } from 'react';
import DesignersAPI from '../../api/designers';
import PageSpinner from '../PageSpinner';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import SampleCoverPhoto from '../../assets/img/samples/cover.jpg';
import '../../assets/styles/pages/home.scss';

function Home() {

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [designers, setDesigners] = useState([]);

  // verify token on app load
  useEffect(() => {
    if (isLoading)
      DesignersAPI.getAllDesigners().then(({data, success, error}) => {
        setIsLoading(false);
        if (success)
          //setDesigners([...data, ...data, ...data, ...data,...data, ...data, ...data, ...data,...data, ...data, ...data, ...data,...data, ...data, ...data, ...data])
          setDesigners(data);
        else
          setError(error);
      });
  }, [dispatch, isLoading, designers]);

  if (isLoading)
    return <PageSpinner caption="...טוען" />;

  if (error)
    return <div>קרתה שגיאה: {error}</div>;

  const getImage = (designer) => {
    const coverPhoto = designer.Images.find(i => i.section === 'cover');
    if (coverPhoto)
      return coverPhoto.imageUrl + '?t=' + coverPhoto.updatedAt;

    return SampleCoverPhoto;
  };

  return (
    <div className="designers-page">
      <div className="designers-container">
        {designers.map((designer, index) => {
          return <div key={"designer_" + index} className="designer">
            <NavLink to={"/designer/" + designer.id} title={designer.name} style={{backgroundImage: `url(${getImage(designer)})`}} />
            <h3><NavLink to={"/designer/" + designer.id} title={designer.name}>{designer.name}</NavLink></h3>
          </div>;
        })}
    </div>
      <footer><a href="https://www.newroom.co.il/#show-project-popup">מעוניין לעצב חדר</a>&copy; 2020 NewRoom</footer>
    </div>
  );
}


export default Home;
