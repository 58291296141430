import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userLoginAsync } from '../../asyncActions/authAsyncActions';
import '../../assets/styles/pages/login.scss';
import Logo from '../../assets/img/logo/logo_couch.png';
import queryString from '../../utils/queryString';
import Translate from '../Translate';
import { NavLink } from 'react-router-dom';

function Login() {

  const authObj = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const { userLoginLoading, loginError } = authObj;

  const initialUsername = queryString.getParameter('email') || '';

  const username = useFormInput(initialUsername);
  const password = useFormInput('');

  // handle button click of login form
  const handleLogin = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    dispatch(userLoginAsync(username.value, password.value));
  };

  return (
    <div className="login-form">
      <form>
        <h3>כניסה</h3>
        <img src={Logo} alt="NewRoom" />
        <div>
          <div><em>שם משתמש</em></div>
          <input type="text" {...username} autoComplete="username" />
        </div>
        <div>
          <div><em>סיסמא</em></div>
          <input type="password" {...password} autoComplete="password" />
        </div>
        <div className="links"><NavLink to="/forgot">שכחתי סיסמא</NavLink></div>
        <button type="submit" className="btn btn-primary" disabled={userLoginLoading} onClick={handleLogin}>{userLoginLoading ? '...נכנס' : 'כניסה'}</button>
        {loginError && <div className="login-error"><Translate Message={"errors."+ (Array.isArray(loginError) ? loginError[0].msg : loginError)} /></div>}
      </form>

    </div>
  );
}

// custom hook to manage the form input
const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange
  }
};


export default Login;
