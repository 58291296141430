class General {
  static hasFlag(value, flag) {
    return (value &= flag) === flag;
  }

  static removeFlag(value, flag) {
    return value &= ~flag;
  }

  static addFlag(value, flag) {
    return value |= flag;
  }

  static getValueArrayFromFlag(enumValues, flag) {

    const valueArray = [];
    enumValues.forEach(v => {
      if (this.hasFlag(flag, +v))
        valueArray.push(v);
    });
    return valueArray;
  }

  static getFlagValueFromArray(valueArray) {
    let flag = 0;
    valueArray.forEach(v => flag = this.addFlag(flag, v));
    return flag;
  }

  static reorder(arr, currentIndex, newIndex) {
    if (Array.isArray(arr) && currentIndex >= 0 && arr.length > currentIndex) {
      const item = arr.splice(currentIndex, 1)[0];
      arr.splice(newIndex, 0, item);
    }
  }

  static clone(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  static isDeepEqual(obj, obj2) {
    return JSON.stringify(obj) === JSON.stringify(obj2);
  }
}

export default General;
