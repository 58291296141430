import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../../assets/styles/components/_toolbar.scss';
import { useDispatch, useSelector } from 'react-redux';
import { userLogoutAsync } from '../../asyncActions/authAsyncActions';
import { MdMenu } from 'react-icons/md';

const Toolbar = ({items}) => {
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(false);
  const { isAuthenticated } = useSelector(state => state.auth);
  if (!isAuthenticated)
    return null;
  // handle click event of the logout button
  const handleLogout = () => {
    dispatch(userLogoutAsync());
  };
  return(<div className="toolbar">
    <button onClick={() => setMenuOpen(!menuOpen)}><MdMenu /></button>
    <div className={"toolbar-container" + (menuOpen ? ' show' : '')} onClick={() => setMenuOpen(false)}>
      {items.map((item, index) => <NavLink key={"menu_" + index} exact={item.exact || false}  activeClassName="active" to={item.to}>{item.name}</NavLink>)}
      <NavLink activeClassName="active" to="/" onClick={handleLogout}>יציאה</NavLink>
    </div>
  </div>);
};

export default Toolbar;
