import React, { useState } from 'react';
// import $ from 'jquery';
import '../assets/styles/components/_images-slider-modal.scss';

const ImagesSliderModal = ({ className, caption, imageSrc, show, onClose }) => {

  const [isShown, setIsShown] = useState(show);

  if (isShown) {
    // $('body').addClass('disable-scroll');
  }
  else {
     // $('body').removeClass('disable-scroll');
  }

  const onCloseClicked = () => {
    // $('body').removeClass('disable-scroll');
    setIsShown(false);
    onClose();
  };

  return <div className={"images-slider-modal" + (className ? ` ${className}`: '')} style={{display: isShown ? 'flex' : 'none'}}>

      <span className="close" onClick={onCloseClicked}>&times;</span>

      <div className="images-slider-modal-content">
        <img src={imageSrc} alt={caption} />
        <small>{caption}</small>
      </div>

    </div>;
};

export default ImagesSliderModal;
