import React from 'react';
import PropTypes from 'prop-types';
import SaveChangesBar from './SaveChangesBar';
import SearchInput from './SearchInput';
import ActionsFormatter from './formatters/ActionsFormatter';
import '../assets/styles/components/_tableform.scss';
import Converters from '../utils/converters';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import $ from 'jquery';
import general from '../utils/general';

const sortDir = {
  "NONE": "None",
  "ASC": "ASC",
  "DESC": "DESC"
};

class TableForm extends React.Component {

  constructor(props) {
    super(props);

    this.handleUpdateRow = this.handleUpdateRow.bind(this);
    this.handleGridSort = this.handleGridSort.bind(this);
    this.checkSpecialKeys = this.checkSpecialKeys.bind(this);
    this.editModeIds = [];

    const sortColumn = props.InitialSortBy || null;
    const sortDirection = sortDir[props.InitialSortDirection] || (props.InitialSortBy ? sortDir.ASC : sortDir.NONE);

    this.state = {
      rows: this.getRows(props.Rows, sortDirection, sortColumn),
      hasChanges: false,
      message: null,
      loading: true,
      sortColumn: sortColumn,
      sortDir: sortDirection
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.Rows !== this.props.Rows)
      this.setState({rows: this.getRowsByOldOrder()});
  }

  getRowsByOldOrder() {
    const newRows = general.clone(this.props.Rows);
    const {rows: oldRows} = this.state;
    const rows = [];
    oldRows.forEach(({id: oldRowId}) => {
      const newRow = newRows.find(({id: newRowId}) => newRowId === oldRowId);
      if (newRow)
        rows.push(newRow);
    });
    rows.push(...newRows.filter(r => !oldRows.map(({id}) => id).includes(r.id)));
    return rows;
  }

  getRows(rowData, sortDirection, sortColumn){
    let colType = '';

    const comparer = (a, b) => {
      let a_value = a[sortColumn],
        b_value = b[sortColumn];

      // Nulls first/last depending on sorting
      if (sortDirection === sortDir.DESC){
        if (a_value === null)
          return 1;
        if (b_value === null)
          return -1;
      }
      if (sortDirection === sortDir.ASC){
        if (a_value === null)
          return -1;
        if (b_value === null)
          return 1;
      }

      switch (colType) {
        case 'json':
          a_value = JSON.stringify(a_value).toLowerCase();
          b_value = JSON.stringify(b_value).toLowerCase();
          break;
        case 'date':
        case 'datetime':
          a_value = new Date(a_value).getTime();
          b_value = new Date(b_value).getTime();
          break;
        default:
          a_value = String(a_value).toLowerCase();
          b_value = String(b_value).toLowerCase();
          break;
      }

      if (sortDirection === sortDir.ASC) {
        return (a_value > b_value) ? 1 : -1;
      }
      else if (sortDirection === sortDir.DESC) {
        return (a_value < b_value) ? 1 : -1;
      }
    };

    const rows = general.clone(rowData);
    sortDirection = sortDirection || this.state.sortDir;

    if (sortDirection !== sortDir.NONE) {
      const col = this.props.Columns.find(c => c.id === sortColumn);
      colType  = (col && col.type) || '';
      sortColumn = sortColumn || this.state.sortColumn;
      rows.sort(comparer);
    }

    return rows;
  }

  handleUpdateRow(evt){
    const $input = $(evt.target),
      rowId = $input.data('index'),
      colId = $input[0].name,
      value = $input.val();

    this.handleRowUpdate(rowId, colId, value);
  }

  handleRowUpdate(rowId, colId, value, closeEditor = false) {
    const rows = this.state.rows.slice(),
      row = rows.find(r => r.id === rowId),
      col = this.props.Columns.find(c => c.id === colId),
      updated = {[colId]: value};

    this.updateAndValidateRow(row, col, updated);
    const newState = { hasChanges: !general.isDeepEqual(rows, this.getRows(this.props.Rows)), rows };
    if (closeEditor)
      newState.editedRowId = newState.editColumnId = newState.validationError = -1;
    this.setState(newState);
  }

  updateAndValidateRow(row, col, updated){
    this.editModeIds = [...new Set([...this.editModeIds, row.id])];

    Object.keys(updated).forEach(column => {
      row[column] = updated[column];
      this.validateColumn(row, col);
    });
  }

  validateEmail(email) {
    const emailTester = new RegExp('^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$');
    return emailTester.test(email.toLowerCase());
  }

  validateColumn(row, col){
    const columnName = col.id;
    if (col.mandatory && !$.trim(row[columnName]))
      return this.setState({validationError: row.id});

    let hasError = false;

    switch (col.type){
      case "email":
        hasError = !this.validateEmail(row[columnName]);
        break;

      case 'json':
        try {
          if (typeof row[columnName] === 'string')
            row[columnName] = JSON.parse(row[columnName]);
          hasError = false;
        }
        catch (e) {
          hasError = true;
        }
        break;

      default:
        break;
    }

    // Set validation error
    this.setState({validationError: (hasError ? row.id : -1)})
  }

  handleGridSort(evt){
    const sortColumn = $(evt.target).data('sort');
    if (!sortColumn)
      return;
    let sortDirection = this.state.sortDir;
    if (sortColumn === this.state.sortColumn)
      sortDirection = sortDirection === sortDir.DESC ? sortDir.ASC : sortDir.DESC;

    this.setState({ rows: this.getRows(this.props.Rows, sortDirection, sortColumn), sortDir: sortDirection, sortColumn });
  }

  getValuesToUpdate(){
    return this.state.rows
      .map(row => {
        const originalRow = this.props.Rows.find(r => r.id === row.id);
        if (general.isDeepEqual(originalRow, row))
          return false;

        return Object.keys(row)
          .filter(key => {
            return !general.isDeepEqual(row[key], originalRow[key]);
          })
          .reduce((res, key) => {
            res[key] = row[key];
            return res;
          }, {id: row.id});
      })
      .filter(row => row);
  }

  saveChanges(){
    let valuesToUpdate = this.getValuesToUpdate();
    this.setState({message: "Saving ..."});
    this.props.OnSave(valuesToUpdate).then(res => {
      if (res)
        this.onSaveSuccess();
    });
  }

  onSaveSuccess() {
    this.editModeIds = [];
    this.setState({hasChanges: false, editedRowId: -1, editColumnId: -1, validationError: -1, message: null});
  }

  reset() {
    this.editModeIds = [];
    this.setState({rows: this.getRows(this.props.Rows), hasChanges: false, editedRowId: -1, editColumnId: -1, validationError: -1, message: null});
  }

  checkSpecialKeys(evt) {
    if (evt.keyCode === 13 && evt.target.tagName !== 'TEXTAREA') // ENTER
      this.setEditable(-1 , -1, evt);

    if (evt.keyCode === 27) { // ESCAPE
      const row = this.props.Rows.find(({id}) => id === this.state.editedRowId);
      if (row) {
        const oldValue = row[this.state.editColumnId];
        if (oldValue) {
          this.handleRowUpdate(this.state.editedRowId, this.state.editColumnId, oldValue);
          this.setState({editedRowId: -1, editColumnId: -1, validationError: -1, message: null});
        }
      }
    }
  }

  hasError() {
    return this.state.validationError > 0 && this.state.validationError === this.state.editedRowId;
  }

  setEditable(rowId, colId, e) {
    const hasError = this.hasError();

    if ((rowId > -1 || hasError) && e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (hasError) {
      setTimeout(() => {
        $('#EditableFormTable .form-control').focus();
      }, 0);
      return;
    }

    this.setState({editedRowId: rowId, editColumnId: colId});
  }

  isColumnReadonly({readonly}, row) {
    if (readonly === true || readonly === false)
      return readonly;

    if (typeof readonly === "function")
      return readonly(row);

    return false;
  }

  shouldDisplayEditor(row, col) {
    return this.state.editedRowId === row.id && this.state.editColumnId === col.id;
  }

  getActionTypeColumn(row, col) {
    if (!col.allowed || col.allowed(row))
      return <div><ActionsFormatter className={col.className} icon={col.icon} title={col.title} onClick={() => col.onClick(row)} /></div>;
    return null;
  }

  getToggleTypeColumn(row, col) {
    const value = row[col.id];
    return <div><ActionsFormatter className={col.className} icon={value ? col.enableIcon : col.disableIcon} title={value ? col.enableTitle : col.disableTitle} onClick={() => col.onClick(row)} /></div>;
  }

  getJsonTypeColumn(row, col) {
    const {type: columnType} = col,
      isColumnEditable = !this.isColumnReadonly(col, row),
      className = isColumnEditable ? 'editable' : null;

    let value = row[col.id];
    if (typeof(value) === 'object')
      value = JSON.stringify(value, undefined, 2);

    if (isColumnEditable && this.shouldDisplayEditor(row, col)) {
      const textarea = <textarea className={"form-control line" + (this.state.validationError === row.id ? " error" : "")} data-index={row.id} name={col.id} onChange={this.handleUpdateRow} onKeyDown={this.checkSpecialKeys} value={value} />;
      setTimeout(() => {
        $('#EditableFormTable textarea.form-control').focus();
      }, 0);
      return <div>{textarea}</div>;
    }

    if (columnType === 'json')
      return <div><textarea className="readonly-json line" readOnly value={value} /></div>;

    return <div className={className}>{value}</div>;
  }

  getSelectTypeColumn(row, col) {
    const value = row[col.id],
      selectedItem = col.items.find(i => i.value === value),
      label = (selectedItem && selectedItem.label) || value,
      isColumnEditable = !this.isColumnReadonly(col, row),
      isDropdownOpened = row.id === this.state.editedRowId && col.id === this.state.editColumnId;

    if (isColumnEditable)
      return <UncontrolledButtonDropdown className="editable">
                <DropdownToggle color="rgba(255,255,255,0)" caret size="sm">
                  {label}
                </DropdownToggle>
                {isDropdownOpened ? <DropdownMenu>
                  {col.items.map((item, index) => <DropdownItem key={`row_${row.id}_col_${col.id}_ddi_${index}`} active={value === item.value} onClick={() => this.handleRowUpdate(row.id, col.id, item.value, true)}>{item.label}</DropdownItem>)}
                </DropdownMenu> : null}
              </UncontrolledButtonDropdown>;

    return <div>{label}</div>;
  }

  getDateTypeColumn(row, col) {
    const value = row[col.id],
      isColumnEditable = !this.isColumnReadonly(col, row),
      className = isColumnEditable ? 'editable' : null;

    if (isColumnEditable && this.shouldDisplayEditor(row, col)) {
      const input = <input className={"form-control line" + (this.state.validationError === row.id ? " error" : "")} type="date" data-index={row.id} name={col.id} value={Converters.toDateEditorValue(value)} onChange={this.handleUpdateRow} onClick={(evt) => {evt.stopPropagation();}} onKeyDown={this.checkSpecialKeys} onBlur={this.setEditable.bind(this, -1, -1)} />;
      setTimeout(() => {
        $('#EditableFormTable input.form-control').focus();
      }, 0);
      return <div>{input}</div>;
    }
    return <div className={className}>{value ? Converters.toDateString(value) : "-"}</div>
  }

  isSet(value) {
    return value !== undefined && value !== null;
  }

  getRegularTypeColumn(row, col) {
    const value = row[col.id],
      isColumnEditable = !this.isColumnReadonly(col, row),
      className = isColumnEditable ? 'editable' : null;

    if (isColumnEditable && this.shouldDisplayEditor(row, col)) {
      const input = <input className={"form-control line" + (this.state.validationError === row.id ? " error" : "")} type={col.type} data-index={row.id} name={col.id} value={value} placeholder={col.placeholder} onChange={this.handleUpdateRow} onKeyDown={this.checkSpecialKeys} />;
      setTimeout(() => {
        $('input.form-control').focus();
      }, 0);
      return <div>{input}</div>;
    }
    const valueToRender = this.isSet(value) ? value : (this.isSet(col.placeholder) ? col.placeholder : <>&nbsp;</>);
    return <div className={className}>{valueToRender}</div>
  }

  renderInputEditor(row, col) {
    const {type: columnType} = col;

    switch (columnType) {
      case 'renderer':
        return col.render(row, col);

      case 'action':
        return this.getActionTypeColumn(row, col);

      case 'toggle':
        return this.getToggleTypeColumn(row, col);

      case 'json':
      case 'text':
      case 'textarea':
        return this.getJsonTypeColumn(row, col);

      case 'select':
        return this.getSelectTypeColumn(row, col);

      case 'date':
      case 'datetime':
        return this.getDateTypeColumn(row, col);

      default:
        return this.getRegularTypeColumn(row, col);
    }
  }

  renderColumn(col, index) {
    return <div key={"column_" + index} className={this.state.sortColumn === col.id ? ` sorted sort-${this.state.sortDir.toLowerCase()}` : ""}
                data-sort={col.label ? col.id : null}>{col.label}</div>;
  }

  renderValueEditor(col, row) {
    return <div data-title={col.label} key={"row_" + row.id + "_col_" + col.id} onClick={col.type !== 'action' && col.type !== 'toggle' ? this.setEditable.bind(this, row.id, col.id) : null}>
      {this.renderInputEditor(row, col)}
    </div>;
  }

  renderRow(row) {
    let title, className = '';
    if(row.error) {
      title = row.error;
      className = ' row-error';
    }
    return <div title={title} className={"grid-table-row search-line" + className} key={"row_" + row.id}>
      {this.props.Columns.map(col => this.renderValueEditor(col, row))}
    </div>;
  }

  render() {
    return <div className="fl-grid" onClick={this.setEditable.bind(this, -1, -1)}>

        <div className="fl-grid__actions">
          <div>
            {this.props.LeftToolbarAction}
          </div>
          <div>
            <SearchInput containerId="EditableFormTable" addCssClass="search-bar" />
          </div>
        </div>

        {/* START: GRID */}

        <div className="g-container" id="EditableFormTable">
          <div className="grid-table-row g-header" onClick={this.handleGridSort}>
            {this.props.Columns.map((col, index) => this.renderColumn(col, index))}
          </div>
          {this.state.rows.map(row => this.renderRow(row))}
        </div>
        <SaveChangesBar HasChanges={this.state.hasChanges} DisableSave={this.hasError() || !!this.state.message} Message={this.state.message} OnCancel={() => this.reset()} OnSave={() => this.saveChanges()} />
      </div>;
  }
}

TableForm.propTypes = {
  LeftToolbarAction: PropTypes.object,
  Columns: PropTypes.array.isRequired,
  Rows: PropTypes.array.isRequired,
  OnSave: PropTypes.func.isRequired,
  InitialSortBy: PropTypes.string,
  InitialSortDirection: PropTypes.string,
  Errors: PropTypes.array
};

export default TableForm;
