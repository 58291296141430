import axios from 'axios';

class SimulationsAPI {
  constructor() {
    this.baseUrl = 'simulations';
  }

  getAll() {
    return axios.get(this.baseUrl);
  }

  create(data) {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      const value = data[key];
      formData.append(key, value);
    });
    return axios.post(`${this.baseUrl}`, formData, {
      headers: {'Content-Type': 'multipart/form-data' }
    });
  }

  async delete(simulationId) {
    return await axios.delete(`${this.baseUrl}/${simulationId}`);
  }

  async deleteBulk(simulationIds) {
    return Promise.all(simulationIds.map(id => this.delete(id)));
  }

  async update(simulationId, data) {
    return await axios.patch(`${this.baseUrl}/${simulationId}`, data);
  }
}

export default new SimulationsAPI();
