import React, { useState } from 'react';
import { validateSignature } from '../../api/auth';
import '../../assets/styles/pages/login.scss';
import Logo from '../../assets/img/logo/logo_couch.png';
import PageSpinner from '../PageSpinner';
import {userReset} from '../../api/auth';
import queryString from '../../utils/queryString';
import { NavLink }  from 'react-router-dom';

function Reset(props) {

  const [userData, setUserData] = useState(false);
  const [resetPasswordBusy, setResetPasswordBusy] = useState(false);
  const [isTestingValidity, setIsTestingValidity] = useState(false);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);
  const [resetPasswordError, setResetPasswordError] = useState(null);
  const password = useFormInput('');

  if (!isTestingValidity) {
    setIsTestingValidity(true);
    validateSignature(queryString.getParameter('sig')).then(({success, data, error}) => {
      if (success)
        setUserData(data);
      else
        props.history.push('/login');
    });
  }

  // checking authentication
  if (!userData) {
    return <PageSpinner caption="...טוען" />
  }

  const handleReset = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    setResetPasswordBusy(true);
    userReset(userData.email, password.value).then(({success, error}) => {
      if (success) {
        setResetPasswordSuccess(true);
      }
      else {
        setResetPasswordBusy(false);
        setResetPasswordError("There was a problem changing your password");
      }
    });
  };

  return (
    <div className="login-form">
      <form>
        <h3>בחירת סיסמא</h3>
        <img src={Logo} alt="NewRoom" />
        {resetPasswordSuccess ?
          <div className="reset-success">הסיסמא הוחלפה בהצלחה<br /><br />
          <NavLink exact to={"/login?email=" + userData.email}>הכנס לחשבונך</NavLink></div> :
        <>
        <div>
          <div><em>שם משתמש</em></div>
          <span>{userData.email}</span>
        </div>
        <div>
          <div><em>סיסמא</em></div>
          <input type="password" {...password} autoComplete="new-password" />
        </div>
        <button type="submit" className="btn btn-primary" disabled={resetPasswordBusy} onClick={handleReset}>{resetPasswordBusy ? '...מעדכן' : 'עדכנו סיסמא'}</button>
        {resetPasswordError && <div className="login-error">{resetPasswordError}</div>}
        </>}
        </form>

    </div>
  );
}

// custom hook to manage the form input
const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange
  }
};


export default Reset;
