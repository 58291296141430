import axios from 'axios';
import { getToken } from './auth';
import store from '../store';
import { userLogout } from '../actions/authActions';

axios.defaults.baseURL = process.env.REACT_APP_API_URL || '';

axios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    // If request is different than any of the URLS in urlsExcludedForBearerHeader
    // then send Authorization header with token from localstorage
    const urlsExcludedForBearerHeader = [
      '/login',
      '/forgot',
      '/register',
      '/reset',
      `${window.location.origin}/version.json`
    ];
    if (urlsExcludedForBearerHeader.indexOf(config.url) === -1) {
      config.headers.Authorization = `Bearer ${getToken()}`;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // Do something with response error
    if (error.response) {
      if([401, 403].includes(error.response.data.statusCode) && error.response.data.error === "UNAUTHORIZED") {
        store.dispatch(userLogout());
      }

      return error.response.data;
    }
    return {success: false, statusCode: 500, error};
  }
);
