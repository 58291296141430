import PropTypes from "prop-types";
import React from "react";

class ActionsFormatter extends React.Component {
  render() {
    const Icon = this.props.icon;
    if(this.props.onClick)
      return <Icon title={this.props.title} size={20} className={"icon-20 can-click with-hover" + (this.props.className ? ` ${this.props.className}` : '')} onClick={this.props.onClick} />;
    return null;
  }
}
ActionsFormatter.propTypes = {
  icon: PropTypes.func.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default ActionsFormatter;
