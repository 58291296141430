import PropTypes from 'prop-types';
import React from 'react';
import i18next from 'i18next';

const Translate = ({Message: message}) => {
  return (
    <>{i18next.t(message)}</>
  );
};

Translate.propTypes = {
  Message: PropTypes.string.isRequired
};

export default Translate;
