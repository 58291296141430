import React from 'react';
import CategoriesAPI from '../../api/categories';
import PageSpinner from '../PageSpinner';
import TableForm from '../TableForm';
import ModalManager from "../../utils/modalManager";
import { MdHighlightOff, MdVisibility } from 'react-icons/md';
import './Categories.scss';
import General from '../../utils/general';

const Enums = {
  spaces: {
    1: 'חדר שינה',
    2: 'סלון',
    4: 'חדר ילדים',
    8: 'חדר תינוקות',
    16: 'אמבטיה',
    32: 'מטבח',
    64: 'מרפסת',
    128: 'משרד',
    256: 'חדר נער/ה',
    512: 'אחר'
  }
};

class Categories extends React.Component {

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      new: {parentCategoryId: Number(Object.keys(Enums.spaces)[0])},
      categories: [],
      errors: [],
      loading: true
    };
  }

  componentDidMount() {
    this.fetchAll();
  }

  fetchAll() {
    CategoriesAPI.getAll().then((response) => {
      if (response.success) {
        this.setState({categories: response.data, loading: false, message: null});
        return true;
      }
      else {
        this.props.history.push('/');
      }
    });
  }

  handleSubmit(e){
    this.inviteCallback(e);
    e.preventDefault();
  }

  deleteCategory(userId){
    this.setState({message: "Deleting..."});
    CategoriesAPI.delete(userId).then(() => {
      this.fetchAll();
    });
    return true;
  }

  openDeleteConfirmationModal(userId) {
    let modalButtons = {
                        "Delete" : { handler: this.deleteCategory.bind(this, userId)},
                        "Cancel" : {primary: true}
                       };
    let content = <div>
      Are you sure you want to delete this category?<br />This operation is permanent
    </div>;
    let config = {
        header: "Delete",
        message: content,
        footer: "footer",
        handlers: modalButtons
    };
    ModalManager.createModal(config);
  }

  validateCategory(categoryId) {
    return Object.keys(Enums.spaces).map(s => Number(s)).includes(categoryId);
  }

  inviteCallback(e) {
    const parentCategoryId = this.state.new.parentCategoryId;
    const name = this.state.new.name;
    if (this.validateCategory(parentCategoryId) && !!name) {
      e.target.reset();
      this.setState({message: 'שומר...'});
      return CategoriesAPI.create(this.state.new).then(() => {
        this.setState({new: {parentCategoryId: Number(Object.keys(Enums.spaces)[0])}, message: 'נשמר'});
        this.fetchAll();
        return true;
      });
    } else
      this.setState({message: 'לא ניתן לשמור. תן שם וקטגוריה'});
  }

  saveChanges(valuesToUpdate){
    this.setState({message: "Saving..."});
    return CategoriesAPI.updateMany(valuesToUpdate).then((res) => {
      // TODO: Check if there are errors
      // if (!res)
      //   return false;
      this.fetchAll();
      return true;
    });
  }

  showCategory(userId) {
    this.props.history.push(`/admin/designer/${userId}`);
  }

  getAvailableSpaces() {
    return Object.keys(Enums.spaces).map(v => { return {value: Number(v), label: Enums.spaces[v]} });
  }

  getColumns() {
    return [
      {label: "", id: 'delete', type: 'action', title: "Delete", icon: MdHighlightOff, className: "text-danger", onClick: ({id}) => { this.openDeleteConfirmationModal(id); }},
      {label: "", id: 'view', type: 'action', title: "View", icon: MdVisibility, onClick: ({id}) => this.showCategory(id) },
      {label: "שם", id: "name", type: "string", mandatory: true},
      {label: "קטגוריה", id: "parentCategoryId", type: "select", mandatory: true, items: this.getAvailableSpaces()}
    ];
  }

  getMultipleSelectionField() {
    const section = 'spaces';
    const enumValues = Enums.spaces;
    const enumValueKeys = Object.keys(enumValues).map(e => Number(e));
    const value = this.state.new.parentCategoryId || [enumValueKeys[0]];
    return <select id={section + "Select"} name={section} defaultValue={value ? General.getValueArrayFromFlag(Object.keys(enumValues), value)[0] : enumValueKeys[0]} onChange={(e) => this.setState({new: {name: this.state.new.name, parentCategoryId: Number(e.target.selectedOptions[0].value)}})}>
      {enumValueKeys.map(key => <option key={section + '_edit_' + key} value={key}>{enumValues[key]}</option>)}
    </select>;
  }

  getAddButton() {
    return <form onSubmit={this.handleSubmit}>
                <div className="input-group">
                  <input className="form-control" defaultValue="" type="name" name="name" autoComplete="off" placeholder="שם תת הקטגוריה" onChange={(event) => this.setState({message: null, new: {name: event.target.value, parentCategoryId: this.state.new.parentCategoryId}})} required />
                  {this.getMultipleSelectionField()}
                  <span className="input-group-btn">
                    <button className="btn btn-primary" type="submit" disabled={this.state.message}>הוסף</button>
                  </span>
                </div>
              </form>;
  }

  render() {
    return <main title="Categories" className="categories-page">
      {this.state.loading ? <PageSpinner /> :
      <TableForm Rows={this.state.categories} Columns={this.getColumns()} OnSave={(updatedCategories) => this.saveChanges(updatedCategories)} Errors={this.state.errors} LeftToolbarAction={this.getAddButton()} InitialSortBy="rating" InitialSortDirection="DESC" />
      }
    </main>;
  }



}
export default Categories;
