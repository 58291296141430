import axios from 'axios';

class SuppliersAPI {
  constructor() {
    this.baseUrl = 'suppliers';
  }

  getAll() {
    return axios.get(this.baseUrl);
  }

  update(id, data) {
    return axios.patch(`${this.baseUrl}/${id}`, data);
  }

  updateMany(categories) {
    return axios.post(`/${this.baseUrl}/bulk`, categories);
  }

  create(data) {
    return axios.post(`/${this.baseUrl}`, data);
  }

  delete(id) {
    return axios.delete(`/${this.baseUrl}/${id}`);
  }

}

export default new SuppliersAPI();
