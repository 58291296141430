import { render } from 'react-dom';
import ReactDom from 'react-dom';
import React from 'react';
import Modal from '../components/Modal';
import $ from 'jquery';

let modalRefs = {};
let modalMovements = {};
let key = 0;
const idPrefix = "modal-";

class ModalManager {

  // config: {
  // 	header,
  // 	body,
  // 	footer,
  // 	handlers,
  // 	contentProps,
  // 	modalKey,
  // 	enableClickOutside,
  // 	message,
  // 	disableFocus,
  // 	addCss
  // }

  //renders modal to the dom and returns a key to the modal reference stored in this manager.
  static createModal(config){
    let clickOutside = config.enableClickOutside || false;
    this.deleteEmptyModals();
    let myKey = config.modalKey || key++;
    let id = "";
    let disableFocus = config.disableFocus || false;
    if (!modalRefs.hasOwnProperty(myKey)) {
      id = idPrefix + myKey;
      let modalContainer = "<div id=" + id + "></div>";
      $(modalContainer).appendTo(".modals");
      let modal =
        <Modal id={myKey.toString()}
               isBlocker={config.isBlocker}
               addCss={config.addCss}
               header={config.header}
               body={config.body}
               message={config.message}
               footer={config.footer}
               headless={config.headless}
               handlers={config.handlers}
               containerId={id}
               disableFocus={disableFocus}
               contentProps={config.contentProps || {}}
               persistent={config.persistent}
               enableClickOutside={clickOutside}
               disableEnterListener={config.disableEnterListener || false}
               className="fl-modal"/>;
      modalRefs[myKey] = render(modal, document.getElementById(id));
    }
    return id;
  }
//header, body, footer, handlers, contentProps, modalKey, enableClickOutside, message, disableFocus, currentModalId, addCss)
  static addModal(config){
    let clickOutside = config.enableClickOutside || false;
    this.deleteEmptyModals();
    let myKey = config.modalKey || key++;
    let id = "";
    let disableFocus = config.disableFocus || false;
    if (!modalRefs.hasOwnProperty(myKey)) {
      id = idPrefix + myKey;
      this.moveModalLeft(config.currentModalId, myKey);
      let modalContainer = "<div id=" + id + "></div>";
      $(modalContainer).appendTo(".modals");
      let modal =
      <Modal closeOnManager={this.closeModal.bind(this)} id={myKey.toString()} isBlocker={config.isBlocker} persistent={config.persistent} addCss={config.addCss} header={config.header} body={config.body} message={config.message} footer={config.footer} handlers={config.handlers} containerId={id} disableFocus={disableFocus} contentProps={config.contentProps || {}} enableClickOutside={clickOutside} className="fl-modal"/>;
      modalRefs[myKey] = render(modal, document.getElementById(id));
    }
    return id;
  }

  //this function is being called from a closing modal.
  static closeModal(modalId){
    this.moveModalRight(modalId);
  }

  //This function move modal left and change his opacity to 0, upon closing the curModal the modal will return to his place and opacity
  static moveModalLeft(movedModal, curModal){
    $("#" + idPrefix + movedModal).find(".fl-modal-content").addClass('fl-modal-m-left');
    modalMovements[curModal] = movedModal;
  }

  //This function checks if the modal thats beign closed has moved another modal to the left. if so, move the moved modal back to his place.
  static moveModalRight(closingModal) {
    let movedModal = modalMovements[closingModal];
    if(movedModal){
      $("#" + idPrefix + movedModal).find(".fl-modal-content").removeClass('fl-modal-m-left');
      delete modalMovements[closingModal];
    }
  }

  static replaceModal(config){
    let id = config.modalKey;
    let modal =
    <Modal header={config.header} body={config.body} addCss={config.addCss} isBlocker={config.isBlocker} footer={config.footer} message={config.message} persistent={config.persistent} handlers={config.handlers} containerId={config.modalKey} contentProps={config.contentProps || {}} enableClickOutside={config.enableClickOutside} className="fl-modal"/>;
    let myKey = id.split('-')[1];
    modalRefs[myKey] = render(modal, document.getElementById(id));
    return id;
  }

  static closeModalById(modalId) {
    const modalKey = modalId.replace(idPrefix, "");
    if (modalRefs.hasOwnProperty(modalKey) && modalRefs[modalKey]) {
      let containerId = modalRefs[modalKey].props.children.props.containerId;
      let containerDomElement = document.getElementById(containerId);
      ReactDom.unmountComponentAtNode(containerDomElement);
      $("#" + containerId).remove();
      delete modalRefs[modalKey];
    }
  }

  static deleteEmptyModals(){
    for(let modalKey in modalRefs){
      if (modalRefs.hasOwnProperty(modalKey) && modalRefs[modalKey]) {
        let containerId = modalRefs[modalKey].props.children.props.containerId;
        let containerDomElement = document.getElementById(containerId);
        if(containerDomElement && containerDomElement.children.length === 0) {
          ReactDom.unmountComponentAtNode(containerDomElement);
          $("#" + containerId).remove();
          delete modalRefs[modalKey];
        }
      }
    }
  }

  static convertElementToComponent(element) {
    return function() {
      return {
        render: function() {
          return (element);
        }
      };
    };
  }
}

export default ModalManager;
