import React from 'react';
import { NavLink } from 'react-router-dom';
import '../../assets/styles/pages/404.scss';

const NotFoundPage = () => {
  return (
    <div className="notfound-page">
      <div>
        <h1>(404) Page Not Found Error</h1>
        <p>We could not locate the requested page on our servers</p>
        <p>Go back to <NavLink to="/">safety</NavLink></p>
      </div>
    </div>
  );
};

export default NotFoundPage;
