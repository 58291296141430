import React, { useEffect, useState } from 'react';
import SimulationsAPI from '../../api/simulations';
import CategoriesAPI from '../../api/categories';
import PageSpinner from '../PageSpinner';
import { useDispatch } from 'react-redux';
import Dropzone from 'react-dropzone';
import { MdPhotoCamera } from 'react-icons/md';
import FilesHelper from '../../utils/files-helper';
import General from '../../utils/general';
import './AddSimulations.scss';
import ImagesSliderModal from '../ImagesSliderModal';
import { NavLink } from 'react-router-dom';


const Enums = {
  spaces: {
    1: 'חדר שינה',
    2: 'סלון',
    4: 'חדר ילדים',
    8: 'חדר תינוקות',
    16: 'אמבטיה',
    32: 'מטבח',
    64: 'מרפסת',
    128: 'משרד',
    256: 'חדר נער/ה',
    512: 'אחר'
  }
};

function AddSimulations() {

  const dispatch = useDispatch();
  const [init, setInit] = useState(false);
  const [loadingCaption, setLoadingCaption] = useState('טוען...');
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [parentCategoryId, setParentCategoryId] = useState(null);
  const [error, setError] = useState(null);
  const [files, setFiles] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagesUrl, setImagesUrl] = useState([]);

  // verify token on app load
  useEffect(() => {
    if (!init) {
        setInit(true);
        CategoriesAPI.getAll().then(({data, success, error}) => {
          setLoadingCaption(null);
          if (success)
            setCategories(data);
          else
            setError(error);
        });
      }
  }, [dispatch, loadingCaption, categories, init]);

  if (loadingCaption)
    return <PageSpinner caption={loadingCaption} />;

  if (error)
    return <div>קרתה שגיאה: {error}</div>;

  const reset = () => {
    setFiles([]);
    setImagesUrl([]);
    setSelectedCategories([]);
    setParentCategoryId(null);
    setLoadingCaption(null);
  };

  const resizeImages = (files) => {
    return new Promise((resolve, reject) => {
      const result = [];
      const MAX_WIDTH = 1920;
      const MAX_HEIGHT = 1400;
      const imageFiles = files.reduce((farr, file) => {
        if (file.type.match(/image.*/))
          farr.push(file);
        return farr;
      }, []);
      for (let i=0, len = imageFiles.length ; i<len ; i++) {
        setLoadingCaption("מקווץ תמונות");
        FilesHelper.ResizeImage(files[i], MAX_WIDTH, MAX_HEIGHT).then((resizedImage) => {
          result.push(resizedImage);
          if (result.length === len)
            resolve(result);
        }).catch(reject);
      }
    });
  };

  const onFilesSelected = (selectedFiles) => {
    resizeImages(selectedFiles).then(result => {
      setFiles(result.map(r => r.file));
      setImagesUrl(result.map(r => r.src));
      setLoadingCaption(null);
    });
  };

  const getMultipleSelectionField = () => {
    const selectedCategoryIds = selectedCategories.map(c => c.id);
    const remainingCategories = categories.filter(a => !selectedCategoryIds.includes(a.id));
    const availableSpaces = remainingCategories.map(c => c.parentCategoryId).reduce((res, key) => {
            if (!res.includes(key))
              res.push(key);
            return res;
          }, []);
    const currentSpaceCategories = remainingCategories.filter(c => c.parentCategoryId === parentCategoryId);
    if (!availableSpaces.length)
      return null;
    if (!parentCategoryId || !currentSpaceCategories.length) {
      setParentCategoryId(availableSpaces[0]);
      return null;
    }
    const enumValues = Enums.spaces;
    return <div className="add-category">
      <button className="btn" onClick={(e) => {
      const select = document.getElementById("categoryId");
      const id = Number(select.selectedOptions[0].value);
      if (id) {
        const categories = [...selectedCategories, {parentCategoryId, id}];
        setSelectedCategories(categories);
      }
    }}>הוסף</button>
      <select name="spaces" defaultValue={General.getValueArrayFromFlag(Object.keys(enumValues), parentCategoryId)[0]} onChange={(e) => setParentCategoryId(Number(e.target.selectedOptions[0].value))}>
      {availableSpaces.map(key => <option key={'spaces_edit_' + key} value={key}>{enumValues[key]}</option>)}
    </select>
    <select id="categoryId" name="categoryId" defaultValue={remainingCategories[0].id}>
      {currentSpaceCategories.map((category) => <option key={'category_option_' + category.id} value={category.id}>{category.name}</option>)}
      </select>
    </div>;
  };

  const getCategories = () => {
    return <div className="selected-categories">
      {selectedCategories.map((c, index) => {
        const space = Enums.spaces[Object.keys(Enums.spaces).find(s => Number(s) === c.parentCategoryId)];
        const category = categories.find(cat => cat.id === c.id);
        return <div key={"category_" + space + '_' + index}>{space} - {category.name}
          <button className="btn btn-secondary btn-delete" onClick={() => {
            const categories = [...selectedCategories];
            categories.splice(index, 1);
            setSelectedCategories(categories);
          }
          }>X</button>
          </div>;
      })}
      {getMultipleSelectionField()}
    </div>;
  };

  const saveSimulation = () => {
    setLoadingCaption('שומר...');
    const payload = files.reduce((result, key) => {
      result[key.name] = key;
      return result;
    }, { categories: selectedCategories.map(s => s.id) });
    SimulationsAPI.create(payload).then(({success, error}) => {
      setLoadingCaption(null);
      if (success) {
        reset();
      }
      else
        setError(error);
    });
  };

  return (
    <>
    {selectedImage ? <ImagesSliderModal imageSrc={selectedImage} show={!!selectedImage} onClose={() => setSelectedImage(null)} /> : null}
    <div className="add-simulations-page">
      <div className="simulations-create">
        <div className="simulation-image">
            <Dropzone multiple={true} accept="image/*" onDrop={onFilesSelected}>
              {({getRootProps, getInputProps}) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="upload"><MdPhotoCamera size={20} />העלו הדמיות...</div>
                  </div>
                </section>
              )}
            </Dropzone>
            {getCategories()}
            <button className="btn btn-primary" onClick={saveSimulation} disabled={!files.length || !selectedCategories.length}>שמור</button>
            <NavLink to="/admin/simulations" className="btn btn-secondary">חזרה</NavLink>
          </div>
        </div>
        <div className="simulations-container">
        {imagesUrl.map((imageUrl, index) => {
          return <div key={"simulation_" + index}>
            <div style={{backgroundImage: `url(${imageUrl})`}} onClick={() => setSelectedImage(imageUrl)} />
          </div>;
        })}
      </div>
      <footer>&copy; 2020 NewRoom</footer>
    </div>
    </>
  );
}

export default AddSimulations;
