import {
  VERIFY_TOKEN_STARTED, VERIFY_USER_SUCCESS, VERIFY_TOKEN_END,
  USER_LOGIN_STARTED, USER_LOGIN_FAILURE, USER_LOGOUT
} from "./actionTypes";
import { removeUserSession, setUserSession } from "../utils/auth";

// verify token - start
export const verifyTokenStarted = (silentAuth = false) => {
  return {
    type: VERIFY_TOKEN_STARTED,
    payload: {
      silentAuth
    }
  }
};

// verify token - end/failure
export const verifyTokenEnd = () => {
  return {
    type: VERIFY_TOKEN_END
  }
};

// user login - start
export const userLoginStarted = () => {
  return {
    type: USER_LOGIN_STARTED
  }
};

// user login - failure
export const userLoginFailure = (error = 'Something went wrong. Please try again later.') => {
  return {
    type: USER_LOGIN_FAILURE,
    payload: {
      error
    }
  }
};

// handle user logout
export const userLogout = () => {
  removeUserSession();
  return {
    type: USER_LOGOUT
  }
};

// verify token - success
export const verifyUserSuccess = ({ token, user }) => {
  setUserSession(token, user);
  return {
    type: VERIFY_USER_SUCCESS,
    payload: {
      token,
      user
    }
  }
};
