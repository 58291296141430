import React, { useState } from 'react';
import { verifyEmail } from '../../api/auth';
import '../../assets/styles/pages/login.scss';
import Logo from '../../assets/img/logo/logo_couch.png';
import PageSpinner from '../PageSpinner';
import { NavLink }  from 'react-router-dom';

function Verify(props) {

  const verification = props.match.params.id;

  const [isTestingValidity, setIsTestingValidity] = useState(false);
  const [validateEmailMessage, setValidateEmailMessage] = useState("מוודא...");
  const [hasError, setHasError] = useState(null);

  if (!isTestingValidity) {
    setIsTestingValidity(true);
    verifyEmail(verification).then(({success, data, error}) => {
      if (success) {
        window.location.href = data.resetLink;
        return;
      }
      setHasError(!success);
      setValidateEmailMessage((success ? 'הדואר האלקטורני אומת בהצלחה' : 'הלינק לא ולידי, או שכתובת הדואר האלקטורני שלכם מאומתת'));
    });
  }

  if (isTestingValidity)
    return <PageSpinner caption="...טוען" />;

  return (
    <div className="login-form">
      <form>
        <h3>אימות דואר אלקטרוני</h3>
        <img src={Logo} alt="NewRoom" />
          <div className={"reset-success" + (hasError ? ' txt-danger' : '')}>{validateEmailMessage}<br /><br />
          <NavLink exact to="/login">הכנס לחשבונך</NavLink>
          </div>
        </form>
    </div>
  );
}

export default Verify;
