import React from 'react';
import SuppliersAPI from '../../api/suppliers';
import PageSpinner from '../PageSpinner';
import TableForm from '../TableForm';
import ModalManager from "../../utils/modalManager";
import { MdHighlightOff, MdVisibility } from 'react-icons/md';
import './Suppliers.scss';

class Suppliers extends React.Component {

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      new: {company: ''},
      suppliers: [],
      errors: [],
      loading: true
    };
  }

  componentDidMount() {
    this.fetchAll();
  }

  fetchAll() {
    SuppliersAPI.getAll().then((response) => {
      if (response.success) {
        this.setState({suppliers: response.data, loading: false, message: null});
        return true;
      }
      else {
        this.props.history.push('/');
      }
    });
  }

  handleSubmit(e){
    this.inviteCallback(e);
    e.preventDefault();
  }

  deleteCategory(supplierId){
    this.setState({message: "Deleting..."});
    Suppliers.delete(supplierId).then(() => {
      this.fetchAll();
    });
    return true;
  }

  openDeleteConfirmationModal(userId) {
    let modalButtons = {
                        "Delete" : { handler: this.deleteCategory.bind(this, userId)},
                        "Cancel" : {primary: true}
                       };
    let content = <div>
      Are you sure you want to delete this supplier?<br />This operation is permanent
    </div>;
    let config = {
        header: "Delete",
        message: content,
        footer: "footer",
        handlers: modalButtons
    };
    ModalManager.createModal(config);
  }

  inviteCallback(e) {
    const company = this.state.new.company;
    if (!!company) {
      e.target.reset();
      this.setState({message: 'שומר...'});
      return SuppliersAPI.create(this.state.new).then(() => {
        this.setState({new: {company: ''}, message: 'נשמר'});
        this.fetchAll();
        return true;
      });
    } else
      this.setState({message: 'לא ניתן לשמור. תן שם חברה'});
  }

  saveChanges(valuesToUpdate){
    this.setState({message: "Saving..."});
    return SuppliersAPI.updateMany(valuesToUpdate).then((res) => {
      // TODO: Check if there are errors
      // if (!res)
      //   return false;
      this.fetchAll();
      return true;
    });
  }

  showSupplier(supplierId) {
    this.props.history.push(`/admin/supplier/${supplierId}`);
  }

  getColumns() {
    return [
      {label: "", id: 'delete', type: 'action', title: "Delete", icon: MdHighlightOff, className: "text-danger", onClick: ({id}) => { this.openDeleteConfirmationModal(id); }},
      {label: "", id: 'view', type: 'action', title: "View", icon: MdVisibility, onClick: ({id}) => this.showSupplier(id) },
      {label: "חברה", id: "company", type: "string", mandatory: true},
      {label: "אתר", id: "website", type: "string"},
      {label: "שם", id: "name", type: "string"},
      {label: "טלפון", id: "phone", type: "string"},
      {label: "תחום", id: "expertise", type: "string"},
      {label: "אחוז הנחה ללקוח", id: "customerDiscount", type: "number"},
      {label: "קוד הנחה", id: "code", type: "string"},
      {label: "אחוז עמלה למעצבת", id: "designerCommission", type: "number"},
      {label: "אחוז עמלה לנו", id: "newroomCommission", type: "number"},
      {label: "תאריך תוקף הסכם", id: "agreementDate", type: "date"},
      {label: "תיאור", id: "description", type: "string"},
      {label: "הערות", id: "comments", type: "string"},
    ];
  }

  getAddButton() {
    return <form onSubmit={this.handleSubmit}>
                <div className="input-group">
                  <input className="form-control" defaultValue="" type="company" name="company" autoComplete="off" placeholder="שם חברה" onChange={(event) => this.setState({message: null, new: {company: event.target.value}})} required />
                  <span className="input-group-btn">
                    <button className="btn btn-primary" type="submit" disabled={this.state.message}>הוסף</button>
                  </span>
                </div>
              </form>;
  }

  render() {
    return <main title="Suppliers" className="suppliers-page">
      {this.state.loading ? <PageSpinner /> :
      <TableForm Rows={this.state.suppliers} Columns={this.getColumns()} OnSave={(updatedSuppliers) => this.saveChanges(updatedSuppliers)} Errors={this.state.errors} LeftToolbarAction={this.getAddButton()} InitialSortBy="rating" InitialSortDirection="DESC" />
      }
    </main>;
  }



}
export default Suppliers;
