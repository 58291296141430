import axios from 'axios';

class DesignersAPI {
  constructor() {
    this.baseUrl = 'designers';
  }

  getAll() {
    return axios.get(this.baseUrl);
  }

  getAllDesigners() {
    return axios.get(`${this.baseUrl}/all`);
  }

  getEditDetailsById(id) {
    return axios.get(`${this.baseUrl}/private/${id}`);
  }

  getById(id) {
    return axios.get(`${this.baseUrl}/${id}`);
  }

  update(id, data) {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      const value = data[key];
      formData.append(key, value);
    });
    return axios.patch(`${this.baseUrl}/${id}`, formData, {
      headers: {'Content-Type': 'multipart/form-data' }
    });
  }

  async deleteImage(userId, imageId) {
    return await axios.delete(`${this.baseUrl}/${userId}/image/${imageId}`);
  }

  async like(userId) {
    return await axios.patch(`${this.baseUrl}/${userId}/like`);
  }

  updateMany(users) {
    return axios.post(`/users/bulk`, users);
  }

  create(data) {
    return axios.post('/users', data);
  }

  invite(data) {
    return axios.post('/users/invite', data);
  }

  delete(id) {
    return axios.delete(`/users/${id}`);
  }

}

export default new DesignersAPI();
