import React from 'react';
import DesignersAPI from '../../api/designers';
import PageSpinner from '../../components/PageSpinner';
import ImagesSliderModal from '../../components/ImagesSliderModal';
import Converters from '../../utils/converters';
import { WhatsappIcon } from 'react-share';
import { MdPhone } from 'react-icons/md';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import '../../assets/styles/pages/userdrilldown.scss';
import General from '../../utils/general';
const SampleProfilePhoto = 'https://static.newroom.co.il/assets/profile.jpg';
const SampleCoverPhoto = 'https://static.newroom.co.il/assets/cover.jpg';

const spacesImages = {
  1: 'https://static.newroom.co.il/assets/bedroom.svg',
  2: 'https://static.newroom.co.il/assets/livingroom.svg',
  4: 'https://static.newroom.co.il/assets/chidrenroom.svg',
  8: 'https://static.newroom.co.il/assets/babyroom.svg',
  16: 'https://static.newroom.co.il/assets/bathroom.svg',
  32: 'https://static.newroom.co.il/assets/kitchen.svg',
  64: 'https://static.newroom.co.il/assets/balcony.svg',
  128: 'https://static.newroom.co.il/assets/office.svg',
  256: 'https://static.newroom.co.il/assets/teenager.svg',
  512: 'https://static.newroom.co.il/assets/other.svg',
};

const Enums = {
  locations: {
    1: 'מרכז',
    2: 'דרום',
    4: 'צפון',
    8: 'ירושלים',
    16: 'כל הארץ',
    32: 'חו״ל',
    64: 'כל הארץ והעולם'
  },
  styles: {
    1: 'כפרי',
    2: 'מודרני',
    4: 'רטרו',
    8: 'תעשייתי',
    16: 'נורדי',
    32: 'בוהו שיק',
    64: 'אקלקטי',
    128: 'פרובאנס',
    256: 'כל הסגנונות'
  },
  spaces: {
    1: 'חדר שינה',
    2: 'סלון',
    4: 'חדר ילדים',
    8: 'חדר תינוקות',
    16: 'אמבטיה',
    32: 'מטבח',
    64: 'מרפסת',
    128: 'משרד',
    256: 'חדר נער/ה',
    512: 'אחר',
    1024: 'הכל'
  },
  budget: {
    1: 'עד 10,000 ש״ח',
    2: 'מ-10,000-30,000 ש״ח',
    4: 'מעל 30,000 ש״ח',
    8: 'כל התקציבים'
  }
};


class UserDrillDownPage extends React.Component {

  constructor(props) {
    super(props);

		this.userId = this.props.match.params.id;

		if (!this.userId)
		  this.props.history.push('/');

		this.changes = {};

		this.myLikes = this.getMyLikes();

    this.state = {
      user: null,
      loading: true,
      selectedImage: null
    };
  }

  componentDidMount() {
    this.setState({loading: true});
    this.fetchUser();
  }

  fetchUser() {
    DesignersAPI.getById(this.userId).then(result => {
      if (!result.success)
        this.props.history.push('/');
      else {
        this.userData = result.data;
        this.reset();
      }
    });
  }

  reset() {
    this.setState({user: General.clone(this.userData), selectedImage: null, loading: false})
  }

  getMyLikes() {
    const likes = localStorage.getItem('liked');
    if (likes) {
      try {
        const arrayLikes = likes.split(',');
        if (Array.isArray(arrayLikes))
          return arrayLikes;
      }
      catch (e) {
      }
    }
    return [];
  }

  get WasLiked() {
    return this.myLikes.includes(this.userId);
  }

  get NumberOfLikes() {
    return (this.state.user.AdditionalInfo && this.state.user.AdditionalInfo.numberOfLikes) || 0;
  }

  onLikeClicked() {
    if (!this.WasLiked) {
      DesignersAPI.like(this.userId).then(result => {
        if (result.success) {
          this.userData = result.data;
          this.reset();
        }
      });
      const user = General.clone(this.state.user);
      if (!user.AdditionalInfo)
        user.AdditionalInfo = {numberOfLikes: 0};
      user.AdditionalInfo.numberOfLikes++;
      this.setState({user});
      this.myLikes.push(this.userId);
      localStorage.setItem('liked', this.myLikes.join());
    }
  }

  getLikeTooltipText() {
    if (this.NumberOfLikes)
      return "אהוב";

    return "היה הראשון לאהוב";
  }

  renderLikeButton() {
    if (this.WasLiked)
      return <FaHeart />;
    return <FaRegHeart />;
  }

  openSendWhatsApp() {
    window.open(`https://api.whatsapp.com/send?phone=972584000263&text=מעוניין לקבל הצעת מחיר מהמעצבת ${this.state.user.name} (קוד מעצבת ${this.state.user.id})`, 'newroom_send_whatsapp');
  }

  getListItem(section, key, value) {
    if (section === 'spaces')
      return <li style={{backgroundImage: `url(${spacesImages[key]})`}} key={`${section}_${key}`}>{value}</li>;
    return <li key={`${section}_${key}`}>{value}</li>;
  }

  getMultipleSelectionField(section) {
    const enumValues = Enums[section];
    const enumValueKeys = Object.keys(enumValues);
    const value = (this.state.user.AdditionalInfo && this.state.user.AdditionalInfo[section]);
    const selectedValues = value ? enumValueKeys.filter(key => General.hasFlag(value, +key)) : null;
    return <ul className={section + '-section'}>
            {selectedValues ? selectedValues.map(key => this.getListItem(section, key, enumValues[key])) : <li>{enumValues[enumValueKeys[enumValueKeys.length-1]] || 'ללא הגבלה'}</li>}
          </ul>;
  }

  getOnline() {
    return <div>{this.state.user.AdditionalInfo && this.state.user.AdditionalInfo.online ? 'כן': 'לא'}</div>;
  }

  showModalDialog({target}) {
    this.showmodaltimeout = setTimeout(() => {
      let backgroundImage = (target.currentStyle || window.getComputedStyle(target, false)).backgroundImage;
      backgroundImage = backgroundImage.slice(4, -1).replace(/"/g, "");
      this.setState({selectedImage: { url: backgroundImage, caption: target.title }});
    }, 100);
  }

  getProfilePhoto() {
    const profilePhoto = this.state.user.Images.find(image => image.section === 'profile');
    if (profilePhoto)
      return profilePhoto.imageUrl + '?t=' + profilePhoto.updatedAt;

    return SampleProfilePhoto;
  }

  getCoverPhoto() {
    const coverPhoto = this.state.user.Images.find(image => image.section === 'cover');
    if (coverPhoto)
      return coverPhoto.imageUrl + '?t=' + coverPhoto.updatedAt;

    return SampleCoverPhoto;
  }

  getDesignerImages() {
    const userImages = this.state.user.Images.filter(i => i.section === 'regular');
    let images = userImages.map((image, index) => <li key={'image_' + index} title={image.caption} style={{backgroundImage: `url(${image.imageUrl}?t=${image.createdAt})`}} onMouseUp={(evt) => this.showModalDialog(evt)}></li>);
    if (!images.length)
      images = [0,1,2,3,4,5,6,7,8,9].map(i => <li key={"example_" + i} title="קרדיט: ניורום" onMouseUp={(evt) => this.showModalDialog(evt)}><em>דוגמא</em></li>);
    return images;
  }

  render() {
    if (this.state.loading)
      return <PageSpinner />;

    return (<>
      {this.state.selectedImage ? <ImagesSliderModal imageSrc={this.state.selectedImage && this.state.selectedImage.url} caption={this.state.selectedImage && this.state.selectedImage.caption} show={!!this.state.selectedImage} onClose={() => this.setState({selectedImage: null})} /> : null}
      <div className='user-drilldown'>
        {this.state.saving ?  <PageSpinner caption={this.state.saving} /> : null}
        <div className="user-header">
          <div className="cover-photo" style={{"backgroundImage": `url(${this.getCoverPhoto()})`}} />
          <div className="user-info">
            <div className="profile-photo-container">
              <div className="profile-photo">
                <div className="image-container" style={{"backgroundImage": `url(${this.getProfilePhoto()})`}} />
                <div className="action-share" onClick={() => this.openSendWhatsApp()}>
                  <WhatsappIcon size={26} round />
                </div>
              </div>
            </div>
            <h2>{this.state.user.name}</h2>
            {this.state.user.phone ? <h3 className="phone"><a href={`tel:${this.state.user.phone}`}><MdPhone />{this.state.user.phone}</a></h3> : null}
            <p className="likes"><span className={"like-action" + (this.WasLiked ? " disabled" : "")} onClick={() => this.onLikeClicked()}>{this.renderLikeButton()}<em>{this.NumberOfLikes > 0 ? Converters.thousandSeparator(this.NumberOfLikes) : ""}</em></span>{!this.WasLiked ? <span className="tooltip">{this.getLikeTooltipText()}</span> : null}</p>
          </div>
        </div>
        <div className="user-content">
          <div className="main">
            <ul className="images">
              {this.getDesignerImages()}
            </ul>
          </div>
           <div className="sidebar">
            <div>
              <h4>מיקום גיאוגרפי</h4>
              {this.getMultipleSelectionField('locations')}
            </div>
            <div className="toggle-button">
              <h4>מעצב/ת אונליין</h4>
              {this.getOnline()}
            </div>
            <div>
              <h4>סגנונות</h4>
              {this.getMultipleSelectionField('styles')}
            </div>
            <div>
              <h4>סוגי חללים</h4>
              {this.getMultipleSelectionField('spaces')}
            </div>
            <div>
              <h4>תקציבי לקוח</h4>
               {this.getMultipleSelectionField('budget')}
            </div>
          </div>
        </div>
        <footer><a href="https://www.newroom.co.il/#show-project-popup">מעוניין לעצב חדר</a>&copy; 2020 NewRoom</footer>
      </div>
    </>);
  }
}
export default UserDrillDownPage;
