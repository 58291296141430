import React from 'react';
import UserAPI from '../../api/designers';
import {getResetLink} from '../../api/auth';
import PageSpinner from '../PageSpinner';
import TableForm from '../TableForm';
import ModalManager from "../../utils/modalManager";
import StatusFormatter from '../formatters/StatusFormatter';
import { MdHighlightOff, MdVisibility, MdShare } from 'react-icons/md';
import '../../assets/styles/pages/users.scss';
import { getUser } from '../../utils/auth';
import { WhatsappShareButton, WhatsappIcon } from 'react-share';
import $ from 'jquery';

class Users extends React.Component {

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
		this.currentUser = getUser();

    this.state = {
      inviteUser: '',
      users: [],
      errors: [],
      loading: true
    };
  }

  componentDidMount() {
    this.fetchAll();
  }

  fetchAll() {
    UserAPI.getAll().then((response) => {
      if (response.success) {
        this.setState({users: response.data, loading: false, message: null});
        return true;
      }
      else {
        this.props.history.push('/');
      }
    });
  }

  handleSubmit(e){
    this.inviteCallback();
    e.preventDefault();
  }

  deleteUser(userId){
    this.setState({message: "Deleting..."});
    UserAPI.delete(userId).then(() => {
      this.fetchAll();
    });
    return true;
  }

  openDeleteConfirmationModal(userId) {
    let modalButtons = {
                        "Delete" : { handler: this.deleteUser.bind(this, userId)},
                        "Cancel" : {primary: true}
                       };
    let content = <div>
      Are you sure you want to delete this user?<br />This operation is permanent
    </div>;
    let config = {
        header: "Delete",
        message: content,
        footer: "footer",
        handlers: modalButtons
    };
    ModalManager.createModal(config);
  }

  validateEmail(email) {
    const emailTester = new RegExp('^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$');
    return emailTester.test(email.toLowerCase());
  }

  inviteCallback() {
    const email = this.state.inviteUser;
    if (!email)
      return;
    if (this.validateEmail(email)) {
      this.setState({message: 'שומר...'});
      return UserAPI.invite({email}).then(() => {
        this.setState({inviteUser: '', message: 'נשמר'});
        this.fetchAll();
        return true;
      });
    }
    this.setState({message: 'אימייל לא חוקי'});
  }

  resendInvitation(email, role) {
    this.setState({inviteUser: email}, () => {
      this.inviteCallback();
      this.showResendSuccess(email, role);
    });
  }

  showResendSuccess(email, role){
    const config = {
        header: "הזמנה",
        message: <div>An invitation email was resent to {email}</div>,
        handlers: {"סגירה" : { primary: true}}
    };
    ModalManager.createModal(config);
  }

  getUserStatus(userData){
    if(userData.isPending)
      return {func: this.resendInvitation.bind(this, userData.email, userData.roles)};
    return null;
  }

  saveChanges(valuesToUpdate){
    this.setState({message: "Saving..."});
    return UserAPI.updateMany(valuesToUpdate).then((res) => {
      // TODO: Check if there are errors
      // if (!res)
      //   return false;
      this.fetchAll();
      return true;
    });
  }

  showUser(userId) {
    this.props.history.push(`/admin/designer/${userId}`);
  }

  getShareableLink(email) {
    getResetLink(email).then((result) => {
      if (result.success) {
        const config = {
          header: "לינק לכניסה למערכת",
          message: <div className="shareable-link">
            <textarea readOnly onClick={(e) => $(e.target).select()}>{result.data}</textarea>
            <WhatsappShareButton className="action-share" url={result.data}>
              <WhatsappIcon size={26} round /> שלח בוואטסאפ
            </WhatsappShareButton>
          </div>,
          handlers: {"סגירה" : { primary: true}}
        };
        ModalManager.createModal(config);
      }
      else
        alert(JSON.stringify(result.error));
    });
  }

  renderStatus(row) {
    return <StatusFormatter value={this.getUserStatus(row)} />;
  }

  getColumns() {
    return [
      {label: "", id: 'delete', type: 'action', title: "Delete", icon: MdHighlightOff, className: "text-danger", allowed: ({id}) => id !== this.currentUser.id, onClick: ({id}) => {if (id !== this.currentUser.id) this.openDeleteConfirmationModal(id); }},
      {label: "", id: 'view', type: 'action', title: "View", icon: MdVisibility, onClick: ({id}) => this.showUser(id) },
      {label: "", id: 'reset', type: 'action', title: "Send", icon: MdShare, onClick: ({email}) => this.getShareableLink(email) },
      {label: "שם", id: "name", type: "string", mandatory: true},
      {label: "אימייל", id: "email", type: "email", mandatory: true},
      {label: "טלפון", id: "phone", type: "string", mandatory: true},
      {label: "דירוג", id: "rating", type: "string"},
      {label: "סוג משתמש", id: "role", type: "select", items: [{label:"רגיל", value: "user"},{label: "פרימיום", value: "premium"}]},
      {label: "תאריך יצירה", id: "createdAt", type: "datetime", readonly: true},
      {label: "תאריך כניסה אחרון", id: "lastLoginDate", type: "datetime", readonly: true}
    ];
  }

  getAddButton() {
    return <form onSubmit={this.handleSubmit}>
                <div className="input-group">
                  <input className="form-control" type="email" name="email" autoComplete="off" placeholder="example@domain.com" value={this.state.inviteUser} onChange={(event) => this.setState({message: null, inviteUser: event.target.value})} required />
                  <span className="input-group-btn">
                    <button className="btn btn-primary" type="submit" disabled={this.state.message} onClick={() => this.inviteCallback()}>Invite</button>
                  </span>
                </div>
              </form>;
  }

  render() {
    return <main title="Users" className="users-page">
      {this.state.loading ? <PageSpinner /> :
      <TableForm Rows={this.state.users} Columns={this.getColumns()} OnSave={(updatedUsers) => this.saveChanges(updatedUsers)} Errors={this.state.errors} LeftToolbarAction={this.getAddButton()} InitialSortBy="rating" InitialSortDirection="DESC" />
      }
    </main>;
  }



}
export default Users;
