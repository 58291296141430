import PropTypes from 'prop-types';
import React from 'react';
import '../assets/styles/components/_save-changes-bar.scss';

const SaveChangesBar = ({HasChanges: hasChanges, DisableSave: disableSave, Message: message, OnSave: onSave, OnCancel: onCancel}) => {
  return (
    <div className={"save-changes-bar" + (message || hasChanges ? " show-footer" : "")}>
      <div>
        {hasChanges ? <>
          <button className="btn btn-primary" disabled={disableSave} onClick={onSave}>שמור</button>
          <button className="btn" onClick={onCancel}>ביטול</button></> : null
        }
      {message ? <em>{message}</em> : null}
      </div>
    </div>
  );
};

SaveChangesBar.propTypes = {
  DisableSave: PropTypes.bool,
  HasChanges: PropTypes.bool.isRequired,
  Message: PropTypes.string,
  OnSave: PropTypes.func.isRequired,
  OnCancel: PropTypes.func
};

export default SaveChangesBar;
